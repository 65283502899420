import {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { SetupWorkerApi } from "msw";

import ToggleOff from "@sellernote/_sds-v2/src/components/svgIcons/ToggleStateOffIcon";
import ToggleOn from "@sellernote/_sds-v2/src/components/svgIcons/ToggleStateOnIcon";
import { TableRow } from "@sellernote/_sds-v2/src/components/table/Table";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import useLocalStorage from "../../hooks/common/useLocalStorage";
import {
  getHandlersToReset,
  printMessageWithActiveHandlers,
} from "../../services/msw";

import { MSWToolMode } from "./useChangeAllHandlers";
import { Handler } from "./useHandlerData";

export type MSWAPIType =
  | "shipda"
  | "boful"
  | "content"
  | "localPrinter"
  | "networkPrinterForHanjinAndOverseas"
  | "networkPrinterForCJ"
  | "shipdaAdmin";

export interface HandlerTableItem {
  handler: string;
  active: ReactNode;
}

export default function useHandlerList({
  mode,
  workerRef,
  handlerData,
  setHandlerData,
  searchKeyword,
}: {
  mode: MSWToolMode;
  workerRef: MutableRefObject<SetupWorkerApi | undefined>;
  handlerData: Handler[];
  setHandlerData: Dispatch<SetStateAction<Handler[]>>;
  searchKeyword: string;
}) {
  const [mswAPIType, setMSWAPIType] = useLocalStorage<MSWAPIType>(
    "mswType",
    "shipda"
  );

  const handleMSWAPITypeChange = useCallback(
    (v: MSWAPIType) => {
      setMSWAPIType(v);
    },
    [setMSWAPIType]
  );

  const handleActiveStateToggle = useCallback(
    (selectedHandler: string) => () => {
      setHandlerData((prevData) => {
        const newData = [...prevData];
        const listOfSelectedAppType = newData.filter(
          (v) => v.type === mswAPIType
        );
        const index = listOfSelectedAppType.findIndex(
          (item) => item.handler === selectedHandler
        );
        listOfSelectedAppType[index].active =
          !listOfSelectedAppType[index].active;

        return newData;
      });

      workerRef.current?.resetHandlers(...getHandlersToReset(handlerData));

      printMessageWithActiveHandlers(workerRef, "Update active handlers");
    },
    [setHandlerData, workerRef, handlerData, mswAPIType]
  );

  const handlerTableList = useMemo((): TableRow<
    "handler" | "endpoint" | "active",
    string
  >[] => {
    const listOfSelectedAppType = handlerData
      .filter((v) => v.type === mswAPIType)
      .filter((cur) => {
        if (!searchKeyword) return true;
        const regexp = new RegExp(searchKeyword, "gi");
        return (
          regexp.test(cur.handler) ||
          (cur.info ? regexp.test(cur.info?.path) : false)
        );
      });

    return listOfSelectedAppType.map((v) => ({
      rowKey: v.handler,
      handler: v.handler,
      endpoint: v.info
        ? `${v.info.method} ${
            v.type.includes("Printer")
              ? v.info.path
              : new URL(v.info.path).pathname
          }`
        : "-",
      active: v.active ? (
        <ToggleOn
          width={47}
          height={47}
          color={mode === "error" ? COLOR.error_300 : COLOR.primary_500}
          onClick={handleActiveStateToggle(v.handler)}
        />
      ) : (
        <ToggleOff
          width={47}
          height={47}
          color={COLOR.grayScale_700}
          onClick={handleActiveStateToggle(v.handler)}
        />
      ),
    }));
  }, [handlerData, mswAPIType, searchKeyword, mode, handleActiveStateToggle]);

  return { mswAPIType, handleMSWAPITypeChange, handlerTableList };
}
