import InputSearch from "@sellernote/_sds-v2/src/components/form/InputSearch";
import Tab from "@sellernote/_sds-v2/src/components/tab/Tab";
import Table, {
  TableRow,
} from "@sellernote/_sds-v2/src/components/table/Table";

import { useCheckIsMobile } from "../../utils/common/hook";

import { MSWAPIType } from "./useHandlerList";
import Styled from "./index.styles";

export default function HandlerList({
  mswAPIType,
  handleMSWAPITypeChange,
  handlerTableList,
  searchKeyword,
  onSearch,
}: {
  mswAPIType: MSWAPIType;
  handleMSWAPITypeChange: (v: MSWAPIType) => void;
  handlerTableList: TableRow<"handler" | "endpoint" | "active", string>[];
  searchKeyword: string;
  onSearch: (v: string) => void;
}) {
  const { isMobile } = useCheckIsMobile();

  return (
    <Styled.HandlerList>
      <Styled.HandlerHeader>
        <Tab<MSWAPIType>
          className="tabList"
          direction="horizontal"
          uiType="rounded"
          tabDataList={[
            {
              title: "Shipda",
              tabValue: "shipda",
            },
            {
              title: "Boful",
              tabValue: "boful",
            },
            {
              title: "Content",
              tabValue: "content",
            },
            {
              title: "shipdaAdmin",
              tabValue: "shipdaAdmin",
            },
            {
              title: "LocalPrinter",
              tabValue: "localPrinter",
            },
            {
              title: "NetworkPrinterForHanjinAndOverseas",
              tabValue: "networkPrinterForHanjinAndOverseas",
            },
            {
              title: "NetworkPrinterForCJ",
              tabValue: "networkPrinterForCJ",
            },
          ]}
          selectedTab={mswAPIType}
          onSelect={handleMSWAPITypeChange}
        />
        <InputSearch
          className="search"
          inputValue={searchKeyword}
          onInputValueChange={(e) => onSearch(e.target.value)}
          onReset={() => onSearch("")}
        />
      </Styled.HandlerHeader>

      <Table
        cellInfos={[
          {
            cellKey: "handler",
            label: "Handler",
            ratio: 2,
            minWidth: "142px",
          },
          {
            cellKey: "active",
            label: "Active",
            ratio: 1,
            verticalAlign: "center",
            minWidth: "80px",
          },
          {
            cellKey: "endpoint",
            label: "Endpoint",
            ratio: 2,
            minWidth: "142px",
          },
        ]}
        rows={handlerTableList}
      />
    </Styled.HandlerList>
  );
}
