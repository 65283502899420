import { rest } from "msw";

import { getBofulDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import { ADMIN_LOCATION_DETAIL } from "./data";

export const getAdminLocationDetail = rest.get(
  getBofulDevAPI("/location/admin/list/:id"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(ADMIN_LOCATION_DETAIL)
    );
  }
);
