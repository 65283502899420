// 글로벌 객체에는 무엇이든 정의될 수 있으므로 any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GlobalObject = any;

/**
 * - Node 전역 객체인 global을 사용해서 프로젝트 전역에서 사용할 수 있는 글로벌 변수를 선언하는데 사용할 수 있습니다.
 * - 싱글턴 패턴(어플리케이션의 life cycle 전체에서 인스턴스가 한번만 만들어져야되는 경우)을 사용할때 유용합니다.
 * - 내부적으로 key로 symbol을 사용하므로 전역에서 unique가 보장됩니다.
 */
export class GlobalRef<T> {
  private readonly sym: symbol;

  constructor(uniqueName: string) {
    this.sym = Symbol.for(uniqueName);
  }

  get value() {
    return (global as GlobalObject)[this.sym] as T | undefined;
  }

  set value(value: T | undefined) {
    (global as GlobalObject)[this.sym] = value;
  }
}
