import { factory, manyOf, oneOf, primaryKey } from "@mswjs/data";
import { commerce, datatype, date, lorem, unique } from "faker";

/**
 * 데이터 모델링
 * 아직 factory에 generic 타입 지원이 안 됨. 오픈소스에 기여할 기회다! https://github.com/mswjs/data/issues/36
 */
export const DB = factory({
  풀필먼트_user: {
    id: primaryKey(() =>
      unique(() => datatype.number({ min: 1, max: 100000 }))
    ),
    accountId: () => datatype.uuid(),
    name: () => "김수입",
    email: () => "kim@ship-da.com",
    phone: () => "01012345678",
    type: () => "consignee",
    company: () => "셀러노트",
    businessType: () => "person",
    authority: () => "manager",
    createdAt: () => "2021-07-14T05:52:48.000Z",
  },

  풀필먼트_입고관리_입고: {
    id: primaryKey(() => datatype.number({ min: 1, max: 100000 })),
    receivingKind: () => "domestic",
    status: () => "putAway",
    inspectionStatus: () => "consent",
    userId: () => unique(() => datatype.number({ min: 1, max: 100000 })),
    quantity: () => 100,
    actualQty: () => 10,
    placeQty: () => 0, // 아직 nullable을 지원안해서 0 으로 할당

    startInspectionAt: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
    completeInspectionAt: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
    agreedAt: () => "2021-08-27T07:30:57.000Z", // 아직 nullable을 지원안해서 "" 으로 할
    dueDate: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
    receivedAt: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
    delivery: () => "parcel",
    expectedDate: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
    workerList: () => [], // 아직 nullable을 지원안해서 "" 으로 할당
    managerId: () => 0, // 아직 nullable을 지원안해서 0 으로 할당

    user: oneOf("풀필먼트_user"),
    items: manyOf("풀필먼트_입고관리_입고_item"),

    totalItem: {
      sku: () => 3,
      quantity: () => 70,
    },

    bid: oneOf("풀필먼트_bid"),
  },

  풀필먼트_bid: {
    // TODO: 보강
    id: primaryKey(() => datatype.number({ min: 1, max: 100000 })),
    delivery: () => "finished",
  },

  풀필먼트_입고관리_입고_item: {
    id: primaryKey(() => datatype.number({ min: 1, max: 100000 })),
    isComplete: () => false,
    inspectorId: () => 0, // 아직 nullable을 지원안해서 0 으로 할당

    quantity: () => 100,
    actualQty: () => 0,

    faultyQty: () => 0,
    startInspectionAt: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
    completeInspectionAt: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
    isCompleteInspection: () => false,

    sku: oneOf("SKU"),
    placeItems: manyOf("풀필먼트_입고관리_입고_item_placeItem"),
  },

  풀필먼트_입고관리_입고_item_placeItem: {
    id: primaryKey(() => datatype.number({ min: 1, max: 100000 })),
    placingId: () => unique(() => datatype.number({ min: 1, max: 100000 })),
    placeQty: () => 0,
    quantity: () => 100,
    placerId: () => 1,
    locationId: () => 0, // 아직 nullable을 지원안해서 0 으로 할당
    isCompletePlacing: () => false,
  },

  SKU: {
    id: primaryKey(() =>
      unique(() => datatype.number({ min: 1, max: 100000 }))
    ),
    itemName: () => commerce.productName(),
    managementCode: () => `A${datatype.number({ min: 1, max: 100000 })}`,
    barCode: () => datatype.number({ min: 1, max: 100000 }),
    code: () => `B${datatype.number({ min: 1, max: 100000 })}`,
    category: () => "의류",

    packages: manyOf("package"),
  },

  풀필먼트_출고관리_출고: {
    id: primaryKey(() =>
      unique(() => datatype.number({ min: 1, max: 100000 }))
    ),
    shippingStatus: () => "inProgress",
    wmsStatus: () => "waiting",
    deliveringStatus: () => "notSent",
    userId: () => unique(() => datatype.number({ min: 1, max: 100000 })),
    managerId: () => 1,
    pickerId: () => 0, // 아직 nullable을 지원안해서 0 으로 할당
    packerId: () => 0, // 아직 nullable을 지원안해서 0 으로 할당
    shipperId: () => 0, // 아직 nullable을 지원안해서 0 으로 할당
    restockerId: () => 0, // 아직 nullable을 지원안해서 0 으로 할당
    truckingId: () => 0, // 아직 nullable을 지원안해서 0 으로 할당
    warehouseId: () => 1,
    dueDate: () => date.soon(3),
    deliveryType: () => "parcel", // parcel | truck
    orderNo: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
    channelId: () => unique(() => datatype.number({ min: 1, max: 100000 })),
    channel: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
    customerName: () => "받는 사람",
    customerPhone: () => "010-1234-5678",
    customerAddress: () => "서울시 강남구",
    customerDetailAddress: () => "역삼동 30",
    customerPostalCode: () => "12345",
    senderName: () => "보내는 사람",
    senderPhone: () => "010-1234-5678",
    senderAddress: () => "서울시 관악구",
    senderDetailAddress: () => "신림동 333",
    senderPostalCode: () => "55123",
    parcelOrder: () => "firstOrder", // firstOrder | secondOrder
    invoiceNo: () =>
      String(datatype.number({ min: 50000000000, max: 70000000000 })),
    isIssue: () => false,
    isConfirm: () => false,
    sentAt: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
    parcelCompany: () => "cj",
    truckCompany: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
    endedPickingAt: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
    isStartScanning: () => false,
    memo: () =>
      [
        {
          memo: lorem.sentence(5),
          category: "customer",
          createdAt: date.past(),
        },
      ] as any,
    createdAt: () => date.past(),
    updatedAt: () => date.past(),
    deletedAt: () => "", // 아직 nullable을 지원안해서 "" 으로 할당

    user: oneOf("풀필먼트_user"),
    items: manyOf("풀필먼트_출고관리_출고_item"),
    picker: oneOf("풀필먼트_user"),
  },

  풀필먼트_출고관리_출고_item: {
    id: primaryKey(() =>
      unique(() => datatype.number({ min: 1, max: 100000 }))
    ),
    skuId: () => datatype.number({ min: 1, max: 100000 }),
    shippingId: () => datatype.number({ min: 1, max: 100000 }),
    locationId: () => datatype.number({ min: 1, max: 100000 }),
    quantity: () => 5,
    currentQty: () => 0,
    createdAt: () => date.past(),
    updatedAt: () => date.past(),
    deletedAt: () => "", // 아직 nullable을 지원안해서 "" 으로 할당

    sku: oneOf("SKU"),
    location: oneOf("location"),
  },

  location: {
    id: primaryKey(() =>
      unique(() => datatype.number({ min: 1, max: 100000 }))
    ),
    warehouseId: () => 1,
    name: () => "PALLET RACK",
    kind: () => "",
    allocatedUserId: () => 0,
    barCode: () =>
      `${datatype.string(1)}-${datatype.string(1)}-${datatype.string(3)}`,
    createdAt: () => date.past(),
    updatedAt: () => date.past(),
    deletedAt: () => "", // 아직 nullable을 지원안해서 "" 으로 할당
  },

  package: {
    id: primaryKey(() =>
      unique(() => datatype.number({ min: 1, max: 100000 }))
    ),
    packingItemId: () => 0,
    buyerId: () => 0,
    buyingURL: () => "",
    maximumLoadWeight: () => "",
    name: () => commerce.productMaterial(),
    description: () => "",
    code: () => "",
    managementCode: () => "",
    barCode: () => "",
    packageCategory: () => "outside",
    packageType: () => "box",
    typeDirectInput: () => "",
    outerType: () => "none",
    width: () => "",
    length: () => "",
    height: () => "",
    provider: () => "customer",
    userId: () => unique(() => datatype.number({ min: 1, max: 100000 })),
    warehouseId: () => 0,
    createdAt: () => date.past(),
    updatedAt: () => date.past(),
    deletedAt: () => "",
  },

  풀필먼트_첨부파일: {
    id: primaryKey(() => datatype.number({ min: 1, max: 100000 })),
    actorKind: () => "warehouseManager",
    bucket: () => "boful-files-dev",
    domain: () => "cargo",
    isBinded: () => true,
    key: () => "cargo/8f99b357-8cfd-4ed8-9bf1-30404d5dd220.png",
    name: () => "intro_illust.png",
    targetId: () => 162,
  },
});

/**
 * mswjs/data의 factory함수(DB)만으로는 여러 데이터가 복합된 구조는 바로 만들 수 없어서 이 함수를 만듦.
 * 복합데이터를 만들어야 하는 경우만 case를 별도로 만들어서 추가하면 됨 (case로 커버하지 것은 단순 생성됨)
 * 특정 속성을 지정하고 싶을 경우 customValue를 사용하면 됨
 */
export function createData(
  type: keyof typeof DB,
  customValue?: { [key: string]: any }
) {
  switch (type) {
    case "풀필먼트_입고관리_입고": {
      const result = DB.풀필먼트_입고관리_입고.create({
        user: DB.풀필먼트_user.create(),
        items: [
          createData("풀필먼트_입고관리_입고_item") as any,
          createData("풀필먼트_입고관리_입고_item") as any,
          createData("풀필먼트_입고관리_입고_item") as any,
          createData("풀필먼트_입고관리_입고_item") as any,
        ],
        bid: DB.풀필먼트_bid.create(),
      });

      return result;
    }

    case "풀필먼트_입고관리_입고_item": {
      const result = DB.풀필먼트_입고관리_입고_item.create({
        sku: DB.SKU.create(),
        placeItems: [
          DB.풀필먼트_입고관리_입고_item_placeItem.create(),
          DB.풀필먼트_입고관리_입고_item_placeItem.create(),
          DB.풀필먼트_입고관리_입고_item_placeItem.create(),
        ],
      });

      return result;
    }

    case "풀필먼트_출고관리_출고": {
      const result = DB.풀필먼트_출고관리_출고.create({
        items: [
          createData("풀필먼트_출고관리_출고_item", {
            skuId: customValue?.skuId,
            locationId: customValue?.locationId,
          }) as any,
          createData("풀필먼트_출고관리_출고_item", {
            skuId: customValue?.skuId + 1,
            locationId: customValue?.locationId + 1,
          }) as any,
          createData("풀필먼트_출고관리_출고_item", {
            skuId: customValue?.skuId + 2,
            locationId: customValue?.locationId + 2,
          }) as any,
        ],
        isIssue: customValue?.isIssue,
        user: customValue?.user,
        picker: customValue?.picker,
        pickerId: customValue?.pickerId,
        endedPickingAt: customValue?.endedPickingAt,
        wmsStatus: customValue?.wmsStatus,
        invoiceNo: customValue?.barCode,
        isStartScanning: customValue?.isStartScanning,
        id: customValue?.id,
        packerId: customValue?.packerId,
      });

      return result;
    }

    case "풀필먼트_출고관리_출고_item": {
      const result = DB.풀필먼트_출고관리_출고_item.create({
        sku: DB.SKU.create({
          id: customValue?.skuId,
          packages: [
            DB.package.create({ packageCategory: "outside" }),
            DB.package.create({
              packageCategory: "product",
              name: "상품포장 샘플",
            }),
            DB.package.create({
              packageCategory: "buffer",
              name: "완충재 샘플",
            }),
          ],
        }),
        skuId: customValue?.skuId,
        locationId: customValue?.locationId,
        location: DB.location.create({ id: customValue?.locationId }),
      });

      return result;
    }

    case "SKU": {
      const result = DB.SKU.create({
        id: customValue?.barCode,
      });

      return result;
    }

    case "location": {
      const result = DB.location.create({
        id: customValue?.locationId,
        barCode: customValue?.barCode,
      });

      return result;
    }

    default: {
      return DB[type].create();
    }
  }
}

type CustomDataType =
  | "풀필먼트_입고관리_입고_상세_res"
  | "풀필먼트_출고관리_의뢰확인_리스트_res"
  | "풀필먼트_출고관리_피킹완료_리스트_res";
/**
 * 원하는 구조의 데이터로 생성하고 싶을때 사용.
 * 신규로 만들때는 CustomDataType에 타입을 추가하고 만들기.
 * (createData는 DB모델링 된 형태로만 만들수 있다는 점에서 다름)
 */
export function createCustomData(type: CustomDataType) {
  switch (type) {
    case "풀필먼트_입고관리_입고_상세_res": {
      return {
        receiving: createData("풀필먼트_입고관리_입고"),
        attachment: [
          DB.풀필먼트_첨부파일.create(),
          DB.풀필먼트_첨부파일.create(),
        ],
      };
    }

    case "풀필먼트_출고관리_의뢰확인_리스트_res": {
      const LIST_TOTAL = 20;

      [...Array(LIST_TOTAL)].forEach((v, i) =>
        createData("풀필먼트_출고관리_출고", {
          user: DB.풀필먼트_user.create(),
          isIssue: i % 2 === 0 ? true : false,
        })
      );

      return {
        total: LIST_TOTAL,
        anotherTotal: 10,
        list: [],
      };
    }

    case "풀필먼트_출고관리_피킹완료_리스트_res": {
      const LIST_TOTAL = 20;

      const list = [...Array(LIST_TOTAL)].map((v, i) =>
        createData("풀필먼트_출고관리_출고", {
          pickerId: 1,
          picker: DB.풀필먼트_user.create(),
          endedPickingAt: date.past(),
        })
      );

      return {
        total: LIST_TOTAL,
        list,
      };
    }
  }
}
