import { useAppQueryWithQueryKeyFactory } from "../../services/query";

import {
  GET_ADMIN_ZONE_ADDRESS_REQ,
  GET_ADMIN_ZONE_ADDRESS_RES,
} from "../../api-interfaces/shipda-api/adminZoneAddress";

export const ADMIN_ZONE_ADDRESS_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_ZONE_ADDRESS_QUERY" }] as const,

  getAdminZoneAddress: (params: GET_ADMIN_ZONE_ADDRESS_REQ) =>
    [
      {
        ...ADMIN_ZONE_ADDRESS_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getZoneAddress",
      },
    ] as const,
};

function useGetAdminZoneAddress(params: GET_ADMIN_ZONE_ADDRESS_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_ZONE_ADDRESS_QUERY_KEY_GEN.getAdminZoneAddress>,
    GET_ADMIN_ZONE_ADDRESS_RES
  >({
    queryKey: ADMIN_ZONE_ADDRESS_QUERY_KEY_GEN.getAdminZoneAddress(params),
    requestOptions: {
      method: "get",
      path: "/zones/address",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },

    keepPreviousData: true,
  });

  return { ...queryResult };
}

const ADMIN_ZONE_ADDRESS_QUERY = {
  useGetAdminZoneAddress,
};

export default ADMIN_ZONE_ADDRESS_QUERY;
