import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../services/query";

import {
  GET_CUSTOMS_TRELLO_BID_DETAIL_REQ,
  GET_CUSTOMS_TRELLO_BID_DETAIL_RES,
  GET_CUSTOMS_TRELLO_BID_FINISHED_LIST_REQ,
  GET_CUSTOMS_TRELLO_BID_FINISHED_LIST_RES,
  GET_CUSTOMS_TRELLO_BID_LIST_REQ,
  GET_CUSTOMS_TRELLO_BID_LIST_RES,
  SEND_CUSTOMS_REQUIRED_DOCUMENT_EMAIL_REQ,
  SEND_CUSTOMS_REQUIRED_DOCUMENT_EMAIL_RES,
  START_CUSTOMS_CLEARANCE_REQ,
  START_CUSTOMS_CLEARANCE_RES,
} from "../../api-interfaces/shipda-api/customsAdminTrello";

export const CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/CUSTOMS_ADMIN_TRELLO_BID_QUERY" }] as const,

  customsTrelloBidList: () =>
    [
      {
        ...CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.all()[0],
        subScope: "customsTrelloBidList",
      },
    ] as const,

  getCustomsTrelloBidList: (params: GET_CUSTOMS_TRELLO_BID_LIST_REQ) =>
    [
      {
        ...CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.customsTrelloBidList()[0],
        ...params,
        entity: "customsTrelloBidList",
      },
    ] as const,

  getCustomsTrelloBidFinishedList: (
    params: GET_CUSTOMS_TRELLO_BID_FINISHED_LIST_REQ
  ) =>
    [
      {
        ...CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.customsTrelloBidList()[0],
        ...params,
        entity: "customsTrelloBidFinishedList",
      },
    ] as const,

  getCustomsTrelloBidDetail: ({ bidId }: GET_CUSTOMS_TRELLO_BID_DETAIL_REQ) =>
    [
      {
        ...CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.all()[0],
        bidId,
        entity: "CustomsTrelloBidDetail",
      },
    ] as const,
};

function useGetCustomsTrelloBidList(
  params: GET_CUSTOMS_TRELLO_BID_LIST_REQ,
  enabled: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.getCustomsTrelloBidList
    >,
    GET_CUSTOMS_TRELLO_BID_LIST_RES
  >({
    keepPreviousData: true,
    queryKey:
      CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.getCustomsTrelloBidList(params),
    enabled,
    requestOptions: {
      method: "get",
      path: "/customs/bid/trello",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useGetCustomsTrelloBidFinishedList(
  params: GET_CUSTOMS_TRELLO_BID_FINISHED_LIST_REQ,
  enabled: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.getCustomsTrelloBidFinishedList
    >,
    GET_CUSTOMS_TRELLO_BID_FINISHED_LIST_RES
  >({
    keepPreviousData: true,
    queryKey:
      CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.getCustomsTrelloBidFinishedList(
        params
      ),
    enabled,
    requestOptions: {
      method: "get",
      path: "/customs/bid/trello",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useGetCustomsTrelloBidDetail({
  bidId,
}: GET_CUSTOMS_TRELLO_BID_DETAIL_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.getCustomsTrelloBidDetail
    >,
    GET_CUSTOMS_TRELLO_BID_DETAIL_RES
  >({
    queryKey: CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.getCustomsTrelloBidDetail({
      bidId,
    }),
    requestOptions: {
      method: "get",
      path: `/customs/bid/trello/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useStartCustomsClearance(bidId: number) {
  const mutation = useAppMutation<
    START_CUSTOMS_CLEARANCE_REQ,
    START_CUSTOMS_CLEARANCE_RES
  >({
    requestOptions: {
      method: "put",
      path: `/customs/bid/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useSendCustomsRequiredDocumentEmail(bidId: number) {
  const mutation = useAppMutation<
    SEND_CUSTOMS_REQUIRED_DOCUMENT_EMAIL_REQ,
    SEND_CUSTOMS_REQUIRED_DOCUMENT_EMAIL_RES
  >({
    requestOptions: {
      method: "put",
      path: `/customs/bid/${bidId}/document/email`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

const CUSTOMS_ADMIN_TRELLO_BID_QUERY = {
  useGetCustomsTrelloBidList,
  useGetCustomsTrelloBidFinishedList,
  useGetCustomsTrelloBidDetail,
  useStartCustomsClearance,
  useSendCustomsRequiredDocumentEmail,
};

export default CUSTOMS_ADMIN_TRELLO_BID_QUERY;
