import { rest } from "msw";

import { getNetworkPrinterForCJAPI } from "../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../constants";

export const printForCJ = rest.post(
  getNetworkPrinterForCJAPI(),
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.text("OK"));
  }
);
