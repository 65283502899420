/**
 * Haversine 공식을 사용하여 두 좌표 사이의 거리를 계산하는 함수
 *
 * @param pinLat - 첫 번째 좌표의 위도
 * @param pinLng - 첫 번째 좌표의 경도
 * @param polylineLat - 두 번째 좌표의 위도
 * @param polylineLng - 두 번째 좌표의 경도
 * @returns 두 좌표 사이의 거리 (킬로미터 단위)
 */
function haversineDistance({
  pinLat,
  pinLng,
  polylineLat,
  polylineLng,
}: {
  pinLat: number;
  pinLng: number;
  polylineLat: number;
  polylineLng: number;
}): number {
  // 지구 반지름 (킬로미터 단위)
  const earthRadiusKm = 6371;

  const deltaLat = ((polylineLat - pinLat) * Math.PI) / 180;
  const deltaLng = ((polylineLng - pinLng) * Math.PI) / 180;

  const haversineFormula =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos((pinLat * Math.PI) / 180) *
      Math.cos((polylineLat * Math.PI) / 180) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);

  const centralAngle =
    2 *
    Math.atan2(Math.sqrt(haversineFormula), Math.sqrt(1 - haversineFormula));

  return earthRadiusKm * centralAngle;
}

/**
 * 특정 좌표와 routes 배열을 받아서 가장 근사치인 인덱스를 반환하는 함수
 *
 * @param pinLat - 기준 좌표의 위도
 * @param pinLng - 기준 좌표의 경도
 * @param routes - 비교할 좌표 배열 (각 요소는 { lat, lng } 객체)
 * @returns 기준 좌표와 가장 가까운 좌표의 인덱스
 *
 * @remarks
 * routes 배열이 비어 있는 경우, -1을 반환합니다.
 */
export function findNearestIndex({
  pinLat,
  pinLng,
  routes,
}: {
  pinLat: number;
  pinLng: number;
  routes: { lat: number; lng: number }[];
}) {
  let nearestIndex = -1;
  let minDistance = Infinity;

  for (let i = 0; i < routes.length; i++) {
    const distance = haversineDistance({
      pinLat,
      pinLng,
      polylineLat: routes[i].lat,
      polylineLng: routes[i].lng,
    });

    if (distance < minDistance) {
      minDistance = distance;
      nearestIndex = i;
    }
  }

  return nearestIndex;
}
