import { useAtomsDevtools } from "jotai-devtools";

import {
  APP_NAME,
  IS_ON_BROWSER,
  IS_UNDER_LOCAL_DEVELOPMENT,
} from "../constants";

const AtomsDevtools = ({ children }: { children: JSX.Element }) => {
  useAtomsDevtools(`jotai-${APP_NAME}`);

  return children;
};

/**
 * Redux DevTool로 Jotai 상태를 디버깅 하기 위한 컴포넌트 (최상위 UI컴포넌트를 감싸는 식으로 사용)
 * - 개발환경에서만 동작하도록 처리됨.
 */
const JotaiDevtools = ({ children }: { children: JSX.Element }) => {
  if (IS_UNDER_LOCAL_DEVELOPMENT && IS_ON_BROWSER)
    return <AtomsDevtools>{children}</AtomsDevtools>;

  return children;
};

export { JotaiDevtools };
