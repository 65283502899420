import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { APP_DEFAULT_TIMEZONE } from "../constants";

/** 필요할때마다 더 추가해서 사용 */
type TimeZone = "Asia/Seoul";

const DAYJS_DEFAULT_LOCALE = (() => {
  return "ko";
})();

/**
 * dayjs 인스턴스 초기화.
 * 전역으로 영향을 주므로, 어플리케이션에서 한 번 만 초기화하면 이후의 인스턴스에 계속 반영된다.
 *
 * <유의사항>
 * - `dayjs 인스턴스`의 타임존과 `타임존 플러그인 인스턴스(dayjs.tz)`의 타임존이 다름에 주의한다.
 *   - `dayjs 인스턴스`의 타임존은 항상 브라우저의 타임존에 따른다(`수정불가`)
 *     - ex) `dayjs(date).utcOffset()`은 브라우저 타임존에 맞게 반환
 *   - `타임존 플러그인 인스턴스(dayjs.tz)`의 타임존은 플러그인 인스턴스의 default 타임존에 따른다(`수정가능`)
 *     - `dayjs.tz.setDefault("Asia/Singapore")`로 default 타임존을 수정할 수 있다.
 *       - 초기화 코드가 아닌 곳에서 default 타임존을 수정하는 경우는 없다.
 *     - ex) `dayjs.tz(date).utcOffset()`은 위에서 수정한 플러그인 타임존에 맞게 반환
 */
function initDayJS() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(isSameOrBefore);
  dayjs.locale(DAYJS_DEFAULT_LOCALE);
  /**
   * 'tz의 default timezone'만 셋팅하는것 임에 유의
   * - 'dayjs 인스턴스'에는 영향을 주지 않음. (dayjs는 host 객체(브라우저)의 timezone에 따름)
   */
  dayjs.tz.setDefault(APP_DEFAULT_TIMEZONE);

  /**
   * IE에서의 timezone버그를 수정
   * (https://github.com/iamkun/dayjs/issues/1151)
   */
  if (new Date().toLocaleString().charCodeAt(0) == 8206) {
    const original = Date.prototype.toLocaleString;
    // 레퍼런스 코드라 lint를 적용하지 않음
    // eslint-disable-next-line no-extend-native
    Date.prototype.toLocaleString = function (...args) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return original.apply(this, args as any).replace(/\u200E/g, "");
    };
  }
}

export type { TimeZone };

export { initDayJS };
