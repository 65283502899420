import { FileTypes } from "../../types/common/upload";

/**
 * 파일 정보와 업로드 URL을 병합하는 함수
 *
 * @param urls - 업로드 URL 목록
 * @param files - 파일 정보 목록
 * @returns 병합된 업로드 URL과 파일 옵션 목록
 */
export function mergeFileInfoToUploadUrls(
  urls: { id: number; url: string }[],
  files: FileTypes[]
) {
  if (!files) return;
  if (!urls) return;

  const fileOptions = files.map(({ fileInfo, id }) => {
    const option = {
      id,
      method: "PUT",
      body: fileInfo,
    };

    return { id, option };
  });

  return urls.map((url, i) => {
    return Object.assign({}, url, fileOptions[i]);
  });
}

/**
 * 바이트를 메가바이트로 변환하는 함수
 *
 * @param bytes - 바이트 단위의 파일 크기
 * @returns 메가바이트 단위의 파일 크기
 */
export function convertBytesToMegabytes(bytes: number) {
  return bytes / (1024 * 1024);
}

export type OptionType = { id: number; method: string; body: File };
export type RequestData = { id: number; url: string; option: OptionType };

type FileSizeUnit = "B" | "KB" | "MB" | "GB" | "TB";

/**
 * 파일 사이즈를 변환해주는 함수
 *
 * @param param0 - 변환할 파일 사이즈와 단위 정보
 * @param param0.size - 파일 크기
 * @param param0.fromUnit - 변환 전 단위
 * @param param0.toUnit - 변환 후 단위
 * @returns 변환된 파일 크기
 */
export function convertFileSize({
  size,
  fromUnit,
  toUnit,
}: {
  size: number;
  fromUnit: FileSizeUnit;
  toUnit: FileSizeUnit;
}) {
  const units = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    TB: 1024 * 1024 * 1024 * 1024,
  };

  const bytes = size * units[fromUnit];

  const convertedSize = bytes / units[toUnit];

  return convertedSize;
}
