export const GET_ADMIN_TEAM_LIST = {
  list: [
    {
      id: 1,
      teamName: "뉴진스",
      numberMembers: 5,
      masterInfo: {
        masterName: "민지",
        masterPhone: "000-0000-0000",
        masterEmail: "123@ac.kr",
      },
      forwardingManagerId: 46,
      searchCount: 100,
      estimateRequestCount: 20,
      progressCount: 4,
      createdAt: "2023-03-07T07:41:26.000Z",
    },
    {
      id: 2,
      teamName: "에스파",
      numberMembers: 4,
      masterInfo: {
        masterName: "카리나",
        masterPhone: "111-0000-0000",
        masterEmail: "1234@ac.kr",
      },
      forwardingManagerId: 46,
      searchCount: 120,
      estimateRequestCount: 30,
      progressCount: 5,
      createdAt: "2023-03-05T07:41:26.000Z",
    },
    {
      id: 3,
      teamName: "아이브",
      numberMembers: 5,
      masterInfo: {
        masterName: "유진",
        masterPhone: "222-0000-0000",
        masterEmail: "12345@ac.kr",
      },
      forwardingManagerId: 46,
      searchCount: 80,
      estimateRequestCount: 10,
      progressCount: 2,
      createdAt: "2023-03-07T07:41:26.000Z",
    },
  ],

  total: 3,
};

export const GET_ADMIN_NEWJEANS_TEAM_DATA = {
  list: [
    {
      id: 1,
      teamName: "뉴진스",
      numberMembers: 5,
      masterInfo: {
        masterName: "민지",
        masterPhone: "000-0000-0000",
        masterEmail: "123@ac.kr",
      },
      forwardingManagerId: 46,
      searchCount: 100,
      estimateRequestCount: 20,
      progressCount: 4,
      createdAt: "2023-03-07T07:41:26.000Z",
    },
  ],

  total: 1,
};

export const GET_ADMIN_IVE_TEAM_DATA = {
  list: [
    {
      id: 3,
      teamName: "아이브",
      numberMembers: 5,
      masterInfo: {
        masterName: "유진",
        masterPhone: "222-0000-0000",
        masterEmail: "12345@ac.kr",
      },
      forwardingManagerId: 46,
      searchCount: 80,
      estimateRequestCount: 10,
      progressCount: 2,
      createdAt: "2023-03-07T07:41:26.000Z",
    },
  ],

  total: 1,
};
