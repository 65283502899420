import { rest } from "msw";

import { getBofulDevAPI } from "../../../services/msw";
import { ScanType } from "../../../types/fulfillment/scan";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import { createData } from "../../db";
import { DUPLICATE_SKU_LIST } from "./data";

export const interpretBarcode = rest.patch(
  getBofulDevAPI("/scanning"),

  (req, res, ctx) => {
    const { type, barCode } = req.body as {
      type: ScanType;
      barCode: string;
    };

    switch (type) {
      case "sku": {
        const skuId = Number(barCode.slice(1));

        const response =
          skuId === 1
            ? [createData("SKU", { barCode: skuId })]
            : DUPLICATE_SKU_LIST;

        return res(
          ctx.status(200),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(response)
        );
        // return res(
        //   ctx.status(404),
        //   ctx.delay(ARTIFICIAL_DELAY_MS),
        //   ctx.json({
        //     code: 404,
        //     error: "not found resource(s)",
        //     message: "not found resource(s)",
        //   })
        // );
      }

      case "location": {
        const locationId =
          barCode === "A-A-001"
            ? 2
            : barCode === "A-A-002"
            ? 3
            : barCode === "A-A-003"
            ? 4
            : 1;

        return res(
          ctx.status(200),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(createData("location", { locationId, barCode }))
        );
      }

      case "shipping":
        return res(
          ctx.status(200),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json({})
        );

      default:
        break;
    }
  }
);
