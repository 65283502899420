import { Port } from "../../types/common/common";
import { TeamBid, TeamSearchLog } from "../../types/forwarding/adminTeam";
import { POBid } from "../../types/forwarding/bid";

import { getCountryCodeKR } from "../common/country";

/**
 * 마케팅용으로 사용하는 port code를 반환
 *
 * @param port - 포트 객체 (선택적)
 * @return 마케팅용 포트 코드 문자열
 */
function toPortCodeForMarketing(port?: Port) {
  if (!port) return "";

  if (port.type === "air") {
    return `${port.country}${port.code}_${port.type.toUpperCase()}`;
  }

  return `${port.code}_${port.type.toUpperCase()}`;
}

/**
 * 주어진 포트 ID에 해당하는 포트 이름을 반환
 *
 * @param portList - 포트 객체 배열 (선택적)
 * @param startPortId - 시작 포트 ID
 * @return 포트 이름 문자열 또는 null
 */
function getPortName(portList: Port[] | undefined, startPortId: number) {
  const portName =
    portList?.find((list: Port) => list.id === startPortId)?.[
      "name" || "nameEN" || "nameC"
    ] || null;

  return portName;
}

/**
 * 포트 ID를 포트 이름으로 변환
 *
 * @param portData - 포트 객체 배열 (선택적)
 * @param portId - 포트 ID
 * @return 포트 이름 문자열
 */
function changePortIdToPortName({
  portData,
  portId,
}: {
  portData?: Port[];
  portId: number;
}) {
  if (!portData) return "";

  const portFoundByPortId = portData.find((port) => {
    return portId === port.id;
  });

  if (!portFoundByPortId) return "";

  return portFoundByPortId.name || portFoundByPortId.nameEN;
}

/**
 * 포트의 이름과 국가 코드를 반환
 *
 * @param port - 포트 객체 (선택적)
 * @return origin과 portName을 포함한 객체
 */
const getPortNameAndOrigin = (port: Port | undefined) => {
  const origin = port?.country ? getCountryCodeKR(port?.country) : "";
  const portName = port?.nameEN;

  return { origin, portName };
};

/**
 * 상세 견적 목록을 위한 포트 이름을 반환
 *
 * @param infoData - 견적 정보 데이터
 * @param type - 시작 포트 ID 또는 종료 포트 ID
 * @param portList - 포트 객체 배열 (선택적)
 * @return 포트 이름 문자열
 *
 * @remarks
 * type이 "startPortId"인 경우, 시작 주소를 포함할 수 있습니다.
 * type이 "endPortId"인 경우, 종료 주소를 포함할 수 있습니다.
 */
const getPortNameForDetailBidList = (
  infoData: TeamBid | TeamSearchLog | POBid,
  type: "startPortId" | "endPortId",
  portList: Port[] | undefined
) => {
  let origin;
  let name;
  let address = "";

  if (infoData[type]) {
    const portInfo = portList?.find((v) => {
      return v.id === infoData[type];
    });

    origin = getPortNameAndOrigin(portInfo).origin;
    name = getPortNameAndOrigin(portInfo).portName;
  }

  if (type === "startPortId") {
    if (infoData.startAddress) {
      address = `내륙 ${infoData.startAddress}/`;
    }
  }

  if (type === "endPortId") {
    if (infoData.endAddress) {
      address = `내륙 ${infoData.endAddress}/`;
    }
  }

  return `${origin} ${address}${name}`;
};

export {
  toPortCodeForMarketing,
  getPortName,
  changePortIdToPortName,
  getPortNameForDetailBidList,
};
