let lastId = 0;

/**
 * 1씩 증가하는 id를 생성.
 * html label id 처럼 한 페이지 내에서만 unique하면 되는 단순한 상황에서 사용.
 * 주의: prefix에 띠라 Application 전체에서 unique할 수 도 있으나 보장은 안 되므로, 완벽한 unique를 원한다면 uuid 라이브러리를 사용해야함
 *
 * @param prefix - 생성될 id의 접두사. 기본값은 "id"입니다.
 * @returns 새로운 id 문자열을 반환합니다. 예: "id1", "id2" 등.
 *
 * @remarks
 * 이 함수는 단순히 1씩 증가하는 id를 생성합니다. 따라서, 한 페이지 내에서만 unique하면 되는 상황에서 사용해야 합니다.
 * Application 전체에서 unique한 id가 필요하다면 uuid 라이브러리를 사용하는 것이 좋습니다.
 */
export default function newId(prefix = "id") {
  lastId++;
  return `${prefix}${lastId}`;
}
