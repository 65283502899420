import { atom } from "recoil";

const USER_LOGGED_IN = atom<undefined | boolean>({
  key: "forwarding/auth/atoms/USER_LOGGED_IN",
  default: undefined,
});

const CURRENT_USER_ID = atom<undefined | number>({
  key: "forwarding/auth/atoms/CURRENT_USER_ID",
  default: undefined,
});

export default {
  USER_LOGGED_IN,
  CURRENT_USER_ID,
};
