import {
  useAppQuery,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import { Country, Liner, Port, Zone } from "../../types/common/common";
import { FileInfo } from "../../types/common/file";
import { ExchangeRate } from "../../types/forwarding/trello";

import { GET_ADMIN_EXCHANGE_RATE_LIST_REQ } from "../../api-interfaces/shipda-api/adminCommon";

export const ADMIN_COMMON_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/COMMON_QUERY" }] as const,

  getZoneList: () =>
    [
      {
        ...ADMIN_COMMON_QUERY_KEY_GEN.all()[0],
        entity: "zoneList",
      },
    ] as const,

  getPortList: () =>
    [
      {
        ...ADMIN_COMMON_QUERY_KEY_GEN.all()[0],
        entity: "portList",
      },
    ] as const,

  getCountryList: () =>
    [
      {
        ...ADMIN_COMMON_QUERY_KEY_GEN.all()[0],
        entity: "countryList",
      },
    ] as const,

  getExchangeList: (params: GET_ADMIN_EXCHANGE_RATE_LIST_REQ) =>
    [
      {
        ...ADMIN_COMMON_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "exchangeList",
      },
    ] as const,

  getLinerList: () =>
    [
      {
        ...ADMIN_COMMON_QUERY_KEY_GEN.all()[0],
        entity: "userLinerList",
      },
    ] as const,
};

function useGetFileUrlList({
  key,
  enabled,
  onSuccess,
  onError,
}: {
  key: string;
  enabled?: boolean;
  onSuccess: (res: FileInfo) => void;
  onError: () => void;
}) {
  const queryResult = useAppQuery<FileInfo>({
    queryKey: ["forwarding", "file", "download", key],
    enabled,
    requestOptions: {
      method: "get",
      path: "/files/download/",
      apiType: "ShipdaAdminDefaultNew",
      params: { key },
    },
    onSuccess,
    onError,
  });

  return { ...queryResult };
}

function useGetZoneList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COMMON_QUERY_KEY_GEN.getZoneList>,
    Zone[]
  >({
    queryKey: ADMIN_COMMON_QUERY_KEY_GEN.getZoneList(),
    requestOptions: {
      method: "get",
      path: "/zones",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useGetPortList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COMMON_QUERY_KEY_GEN.getPortList>,
    Port[]
  >({
    queryKey: ADMIN_COMMON_QUERY_KEY_GEN.getPortList(),
    requestOptions: {
      method: "get",
      path: "/ports",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}
//

function useGetCountryList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COMMON_QUERY_KEY_GEN.getCountryList>,
    Country[]
  >({
    queryKey: ADMIN_COMMON_QUERY_KEY_GEN.getCountryList(),
    requestOptions: {
      method: "get",
      path: "/countries",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useGetExchangeRateList(
  params: GET_ADMIN_EXCHANGE_RATE_LIST_REQ,
  enabled?: boolean,
  onSuccess?: (data: ExchangeRate[]) => void
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COMMON_QUERY_KEY_GEN.getExchangeList>,
    ExchangeRate[]
  >({
    queryKey: ADMIN_COMMON_QUERY_KEY_GEN.getExchangeList(params),
    requestOptions: {
      method: "get",
      path: "/exchange",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    enabled,
    onSuccess,
  });

  return { ...queryResult };
}
function useGetLinerList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COMMON_QUERY_KEY_GEN.getLinerList>,
    Liner[]
  >({
    queryKey: ADMIN_COMMON_QUERY_KEY_GEN.getLinerList(),
    requestOptions: {
      method: "get",
      path: "/liners",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

const ADMIN_COMMON_QUERY = {
  useGetFileUrlList,
  useGetZoneList,
  useGetPortList,
  useGetCountryList,
  useGetExchangeRateList,
  useGetLinerList,
};

export default ADMIN_COMMON_QUERY;
