import { rest } from "msw";

import { getShipdaAdminDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import {
  GET_ADMIN_PROMOTION_BASIC_INFO,
  GET_ADMIN_PROMOTION_DETAIL,
  GET_ADMIN_PROMOTION_TABLE_FINISHED_LIST,
  GET_ADMIN_PROMOTION_TABLE_INPROGRESS_LIST,
  GET_ADMIN_PROMOTION_TABLE_PENDING_LIST,
  GET_ADMIN_PROMOTION_TABLE_WAITING_LIST,
} from "./data";

export const getPromotionListInprogress = rest.get(
  getShipdaAdminDevAPI("/promotion"),
  (req, res, ctx) => {
    const promotionDetailStatus = req.url.searchParams.get(
      "promotionDetailStatus"
    );
    const adminId = req.url.searchParams.get("adminId");
    const perPage = req.url.searchParams.get("perPage");
    const page = req.url.searchParams.get("page");
    if (
      promotionDetailStatus === "inProgress" &&
      perPage === "25" &&
      page === "0" &&
      adminId === "46"
    ) {
      return res(
        ctx.status(200),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(GET_ADMIN_PROMOTION_TABLE_INPROGRESS_LIST)
      );
    }
  }
);

export const getPromotionListWaiting = rest.get(
  getShipdaAdminDevAPI("/promotion"),
  (req, res, ctx) => {
    const promotionDetailStatus = req.url.searchParams.get(
      "promotionDetailStatus"
    );
    const adminId = req.url.searchParams.get("adminId");
    const perPage = req.url.searchParams.get("perPage");
    const page = req.url.searchParams.get("page");
    if (
      promotionDetailStatus === "waiting" &&
      perPage === "25" &&
      page === "0" &&
      adminId === "10"
    ) {
      return res(
        ctx.status(200),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(GET_ADMIN_PROMOTION_TABLE_WAITING_LIST)
      );
    }
  }
);

export const getPromotionListPending = rest.get(
  getShipdaAdminDevAPI("/promotion"),
  (req, res, ctx) => {
    const promotionDetailStatus = req.url.searchParams.get(
      "promotionDetailStatus"
    );
    const adminId = req.url.searchParams.get("adminId");
    const perPage = req.url.searchParams.get("perPage");
    const page = req.url.searchParams.get("page");
    if (
      promotionDetailStatus === "pending" &&
      perPage === "25" &&
      page === "0" &&
      adminId === "10"
    ) {
      return res(
        ctx.status(200),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(GET_ADMIN_PROMOTION_TABLE_PENDING_LIST)
      );
    }
  }
);

export const getPromotionListFinished = rest.get(
  getShipdaAdminDevAPI("/promotion"),
  (req, res, ctx) => {
    const promotionDetailStatus = req.url.searchParams.get(
      "promotionDetailStatus"
    );
    const adminId = req.url.searchParams.get("adminId");
    const perPage = req.url.searchParams.get("perPage");
    const page = req.url.searchParams.get("page");
    if (
      promotionDetailStatus === "finished" &&
      perPage === "25" &&
      page === "0" &&
      adminId === "10"
    ) {
      return res(
        ctx.status(200),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(GET_ADMIN_PROMOTION_TABLE_FINISHED_LIST)
      );
    }
  }
);

export const getPromotionInfoDefault = rest.get(
  getShipdaAdminDevAPI("/promotion/summary/info"),
  (req, res, ctx) => {
    const adminId = req.url.searchParams.get("adminId");
    if (adminId === "10") {
      return res(
        ctx.status(200),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(GET_ADMIN_PROMOTION_BASIC_INFO)
      );
    }
  }
);

export const getPromotionDetail = rest.get(
  getShipdaAdminDevAPI("/promotion/1"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_ADMIN_PROMOTION_DETAIL)
    );
  }
);
