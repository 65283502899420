import { rest } from "msw";

import { getShipdaAdminDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import {
  GET_BID_TRELLO_LIST,
  GET_BID_TRELLO_LIST_DETAIL,
  GET_TRELLO_ATTACHMENTS,
  GET_TRELLO_TRACKING_SHIP_DATA,
} from "./data";

export const getTrelloBidList = rest.get(
  getShipdaAdminDevAPI("/bid/list/trello"),
  (req, res, ctx) => {
    const status = req.url.searchParams.get("status");
    const perPage = req.url.searchParams.get("perPage");
    if (status === "inProgress" && perPage === "300") {
      return res(
        ctx.status(200),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(GET_BID_TRELLO_LIST)
      );
    }
  }
);

export const getTrelloAttachments = rest.get(
  getShipdaAdminDevAPI("/file/102894/attachment"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_TRELLO_ATTACHMENTS)
    );
  }
);

export const getTrelloBidDetail = rest.get(
  getShipdaAdminDevAPI("bid/list/trello/102894"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_BID_TRELLO_LIST_DETAIL)
    );
  }
);

export const getTrelloTrackingShip = rest.get(
  getShipdaAdminDevAPI("tracking/v2/ship/160776"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_TRELLO_TRACKING_SHIP_DATA)
    );
  }
);
