import { rest } from "msw";

import { getShipdaDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import { GET_ADMIN_BID_USER_LIST } from "./data";

export const getBidUserList = rest.get(
  getShipdaDevAPI("/admin/users"),
  (req, res, ctx) => {
    const searchType = req.url.searchParams.get("searchType");
    const registerType = req.url.searchParams.get("registerType");
    const perPage = req.url.searchParams.get("perPage");
    const page = req.url.searchParams.get("page");
    if (
      searchType === "company" &&
      perPage === "25" &&
      page === "0" &&
      registerType === "fclPromotion"
    ) {
      return res(
        ctx.status(200),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(GET_ADMIN_BID_USER_LIST)
      );
    }
  }
);
