/**
 * email validation은 라이브러리(email-validator)를 사용함에 유의
 */
export default {
  password: /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/,
  blank: /[\s]/,
  phone: /^01([016789]?)([0-9]{3,4})([0-9]{4})$/,
  id: /^[a-z0-9]{6,20}$/,
  numberOrEng: /^[a-zA-Z0-9]*$/,
  whiteSpace: /[\s\u0020\u200B\u115F\u1160\u3164\uFFA0\u2800\u3000\u00a0]+/g,
  number: /[^0-9]/gi,
  special: /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g,
  engOrNumberOrBlankOrSpecial:
    /^[\sa-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
