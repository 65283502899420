import * as Sentry from "@sentry/react";

import { APP_ENV, APP_NAME, IS_UNDER_LOCAL_DEVELOPMENT } from "../../constants";

/**
 * CRA용 sentry init 함수.
 * src/index.tsx 에서 호출한다.
 */
const initSentry = () => {
  /**
   * 반드시 `REACT_APP_SENTRY_DSN`으로 써야함에 유의한다.
   * - Sentry 사이트의 integration 설정으로 vercel에 연결하면 자동으로 환경변수들을 vercel에 추가해주는데,
   * - 이때 환경변수는 `SENTRY_DSN`로 추가되어 브라우저 런타임에 사용될 수 없다.
   * - React 프로젝트에서 사용가능하도록 Prefix가 붙은 `REACT_APP_SENTRY_DSN`를 사용해야한다 (vercel 환경변수에 직접추가해줘야 함)
   */
  const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

  if (IS_UNDER_LOCAL_DEVELOPMENT || !SENTRY_DSN) {
    return;
  }

  Sentry.init({
    environment: APP_ENV,
    dsn: SENTRY_DSN,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration(),
    ],
    initialScope: {
      // custom tag
      tags: {
        errorType: "FE",
        appName: APP_NAME,
      },
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export { initSentry };
