export const GET_GROUP_SEARCH_LIST = {
  list: [
    {
      id: 5000000,
      groupName: "groupName",
      productCode: "productCode",
      managementCode: "managementCode",
      userId: 1,
      materialPackageType: "basic",
      returningCondition: "notOpenNotUsed",
      packages: [],
      attachment: [],
      groupItems: [],
    },
    {
      id: 5000001,
      groupName: "groupName",
      productCode: "productCode",
      managementCode: "managementCode",
      userId: 1,
      materialPackageType: "basic",
      returningCondition: "notOpenNotUsed",
      packages: [],
      attachment: [],
      groupItems: [],
    },
    {
      id: 5000002,
      groupName: "groupName",
      productCode: "productCode",
      managementCode: "managementCode",
      userId: 1,
      materialPackageType: "basic",
      returningCondition: "notOpenNotUsed",
      packages: [],
      attachment: [],
      groupItems: [],
    },
  ],
  total: 3,
};

export const DELETE_SINGLE_SKU_FAILURE = {
  code: 422,
  error: "cannot delete SKU",
  sku: [],

  errorSkus: [
    { skuId: 1, status: "packing" },
    { skuId: 7, status: "picking" },
  ],

  productGroup: [
    {
      skuId: 2,
      productGroupIds: [10, 11],
    },
  ],
};
