export const E4000 = {
  code: 400,
  error: "[작업자] 작업 중이 아닌 화물을 피킹완료 하려 한다.",
  errorCode: "E4000",
};

export const E4001 = {
  code: 400,
  error: "[사용자] 출고 요청 수량보다 재고가 더 적다.",
  errorCode: "E4001",
};

export const E4002 = {
  code: 400,
  error: "[작업자] 출고 작업 중 고객사 요청에 의해 주문이 취소된 건이다.",
  errorCode: "E4002",
};

export const E4003 = {
  code: 400,
  error: "[작업자] 취소 작업 중 취소되지 않은 송장을 스캔했다.",
  errorCode: "E4003",
};

export const E4004 = {
  code: 400,
  error: "[매니저] 작업자 지정이 필요하다.",
  errorCode: "E4004",
};

export const E4005 = {
  code: 400,
  error: "아이디 또는 송장번호가 없다.",
  errorCode: "E4005",
};

export const E4006 = {
  code: 400,
  error: "허가되지 않은 아이디와 송장번호이다.",
  errorCode: "E4006",
};

export const E4007 = {
  code: 400,
  error: "지원하지 않는 파일형식이다.",
  errorCode: "E4007",
};

export const E4008 = {
  code: 400,
  error: "작업 중인 송장을 다른 작업자가 스캔했다.",
  errorCode: "E4008",
};

export const E4009 = {
  code: 400,
  error: "[작업자] 현재 작업과 다른 송장이다.",
  errorCode: "E4009",
};

export const E4010 = {
  code: 400,
  error: "[패킹] CJ 예약 접수 API 오류",
  errorCode: "E4010",
};
