/**
 * 파일 확장자를 확인하는 함수
 *
 * @param allowedFileExtensionList - 허용된 파일 확장자 목록
 * @param fileName - 확인할 파일 이름
 * @returns 파일 확장자가 허용된 목록에 포함되어 있는지 여부
 */
const checkFileExtension = ({
  allowedFileExtensionList,
  fileName,
}: {
  allowedFileExtensionList: string[] | undefined;
  fileName: string;
}) => {
  const extension = fileName.split(".").pop() || "";

  return allowedFileExtensionList
    ? allowedFileExtensionList.includes(extension)
    : true;
};

/**
 * 파일 크기를 확인하는 함수
 *
 * @param allowedFileSize - 허용된 파일 크기 (바이트 단위)
 * @param fileSize - 확인할 파일 크기 (바이트 단위)
 * @returns 파일 크기가 허용된 크기 이하인지 여부
 */
const checkFileSize = ({
  allowedFileSize,
  fileSize,
}: {
  allowedFileSize: number | undefined;
  fileSize: number;
}) => (allowedFileSize ? fileSize <= allowedFileSize : true);

/**
 * 파일을 다운로드하는 함수
 *
 * @param fileName - 다운로드할 파일 이름
 * @param fileContent - 다운로드할 파일 내용
 * @param type - 파일의 MIME 타입 (기본값: "application/octet-stream")
 * @returns void
 *
 * @remarks
 * 이 함수는 브라우저 환경에서만 동작합니다. 서버 환경에서는 사용할 수 없습니다.
 */
const downloadFile = ({
  fileName,
  fileContent,
  type = "application/octet-stream",
}: {
  fileName: string;
  fileContent: BlobPart;
  type?: string;
}) => {
  const blob = new Blob([fileContent], { type });

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);

  link.click();

  if (document.body.contains(link)) {
    document.body.removeChild(link);
  }

  window.URL.revokeObjectURL(url);
};

export { checkFileExtension, checkFileSize, downloadFile };
