export const GET_ADMIN_BID_USER_LIST = {
  list: [
    {
      BRN: "1",
      accountId: "a8c0b742-97bf-4ecc-9145-38427dab87aa",
      businessType: "person",
      comments: null,
      company: "셀러노트",
      country: "KR",
      createdAt: "2022-04-25T09:16:37.000Z",
      email: "aaa3@aaaa.net",
      engCompany: null,
      engName: null,
      forwardingManagerId: 46,
      freightType: null,
      fullfillmentManagerId: null,
      grade: "general",
      id: 3273,
      inProgressBidsCount: 0,
      invoiceEmail: null,
      isBelieve: false,
      isCertifiedBL: false,
      isConfirm: false,
      isDel: false,
      isKita: false,
      lastLoginAt: null,
      lastName: null,
      name: "황선재",
      phone: "01012121214",
      registerType: "fclPromotion",
      totalBidsCount: 0,
      totalSearchCount: 0,
      type: "consignee",
      updatedAt: "2022-04-25T09:16:37.000Z",
      version: 2,
    },
    {
      BRN: "1",
      accountId: "a8c0b742-97bf-4ecc-9145-38427dab87aa",
      businessType: "person",
      comments: null,
      company: "셀러노트2",
      country: "KR",
      createdAt: "2022-04-25T09:16:37.000Z",
      email: "aaa3@aaaa.net",
      engCompany: null,
      engName: null,
      forwardingManagerId: 46,
      freightType: null,
      fullfillmentManagerId: null,
      grade: "general",
      id: 3279,
      inProgressBidsCount: 0,
      invoiceEmail: null,
      isBelieve: false,
      isCertifiedBL: false,
      isConfirm: false,
      isDel: false,
      isKita: false,
      lastLoginAt: null,
      lastName: null,
      name: "황선재2",
      phone: "01012121214",
      registerType: "fclPromotion",
      totalBidsCount: 0,
      totalSearchCount: 0,
      type: "consignee",
      updatedAt: "2022-04-25T09:16:37.000Z",
      version: 2,
    },

    {
      BRN: "1",
      accountId: "a8c0b742-97bf-4ecc-9145-38427dab87aa",
      businessType: "person",
      comments: null,
      company: "셀러노트3",
      country: "KR",
      createdAt: "2022-04-25T09:16:37.000Z",
      email: "aaa3@aaaa.net",
      engCompany: null,
      engName: null,
      forwardingManagerId: 46,
      freightType: null,
      fullfillmentManagerId: null,
      grade: "general",
      id: 3272,
      inProgressBidsCount: 0,
      invoiceEmail: null,
      isBelieve: false,
      isCertifiedBL: false,
      isConfirm: false,
      isDel: false,
      isKita: false,
      lastLoginAt: null,
      lastName: null,
      name: "황선재3",
      phone: "01012121214",
      registerType: "fclPromotion",
      totalBidsCount: 0,
      totalSearchCount: 0,
      type: "consignee",
      updatedAt: "2022-04-25T09:16:37.000Z",
      version: 2,
    },

    {
      BRN: "1",
      accountId: "a8c0b742-97bf-4ecc-9145-38427dab87aa",
      businessType: "person",
      comments: null,
      company: "셀러노트4",
      country: "KR",
      createdAt: "2022-04-25T09:16:37.000Z",
      email: "aaa3@aaaa.net",
      engCompany: null,
      engName: null,
      forwardingManagerId: 46,
      freightType: null,
      fullfillmentManagerId: null,
      grade: "general",
      id: 3271,
      inProgressBidsCount: 0,
      invoiceEmail: null,
      isBelieve: false,
      isCertifiedBL: false,
      isConfirm: false,
      isDel: false,
      isKita: false,
      lastLoginAt: null,
      lastName: null,
      name: "황선재4",
      phone: "01012121214",
      registerType: "fclPromotion",
      totalBidsCount: 0,
      totalSearchCount: 0,
      type: "consignee",
      updatedAt: "2022-04-25T09:16:37.000Z",
      version: 2,
    },
  ],
  total: 390,
};
