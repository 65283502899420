export const GET_ADMIN_ORDER_LIST = {
  list: [
    {
      poId: 6,
      userName: "테스트 회사",
      managerName: "손석균",
      poNumber: "6",
      productName: "양말세트",
      currency: "USD",
      exchangeRate: "1200.21",
      isAllSubPoDone: false,
      poStatus: "registered",
      bidLength: 3,
      poProjectStatus: "none",
      poFinishedProjectStatus: [
        {
          status: "failed",
          length: 1,
        },
        {
          status: "canceled",
          length: 1,
        },
        {
          status: "completed",
          length: 1,
        },
      ],
    },
    {
      poId: 6,
      userName: "테스트 회사",
      managerName: "손석균",
      poNumber: "6",
      productName: "양말세트",
      currency: "USD",
      exchangeRate: "1200.21",
      isAllSubPoDone: false,
      poStatus: "registered",
      bidLength: 3,
      poProjectStatus: "none",
      poFinishedProjectStatus: [
        {
          status: "failed",
          length: 1,
        },
        {
          status: "canceled",
          length: 1,
        },
        {
          status: "completed",
          length: 1,
        },
      ],
    },
    {
      poId: 6,
      userName: "테스트 회사",
      managerName: "손석균",
      poNumber: "6",
      productName: "양말세트",
      currency: "USD",
      exchangeRate: "1200.21",
      isAllSubPoDone: false,
      poStatus: "registered",
      bidLength: 3,
      poProjectStatus: "none",
      poFinishedProjectStatus: [
        {
          status: "failed",
          length: 1,
        },
        {
          status: "canceled",
          length: 1,
        },
        {
          status: "completed",
          length: 1,
        },
      ],
    },
  ],
  total: 3,
};
