export const code: { [key: string]: string } = {
  GH: "가나",
  GA: "가봉",
  GY: "가이아나",
  GM: "감비아",
  GP: "프랑스(과데루프섬)",
  GT: "과테말라",
  GU: "미국(괌)",
  GD: "그레나다",
  GE: "그루지야",
  GR: "그리스",
  GL: "덴마크(그린란드)",
  GW: "기니비소",
  GN: "기니",
  NA: "나미비아",
  NG: "나이지리아",
  ZA: "남아프리카공화국",
  NL: "네덜란드",
  AN: "네덜란드(네덜란드령앤틸리스)",
  NP: "네팔",
  NO: "노르웨이",
  NF: "오스트레일리아(노퍽섬)",
  NZ: "뉴질랜드",
  NC: "프랑스(뉴칼레도니아섬)",
  NE: "니제르",
  NI: "니카라과",
  TW: "타이완",
  DK: "덴마크",
  DM: "도미니카연방",
  DO: "도미니카공화국",
  DE: "독일",
  LA: "라오스",
  LV: "라트비아",
  RU: "러시아",
  LB: "레바논",
  LS: "레소토",
  RO: "루마니아",
  RW: "르완다",
  LU: "룩셈부르크",
  LR: "라이베리아",
  LY: "리비아",
  RE: "프랑스(레위니옹섬)",
  LT: "리투아니아",
  LI: "리첸쉬테인",
  MG: "마다가스카르",
  MH: "미국(마아샬제도)",
  FM: "미크로네시아",
  MK: "마케도니아",
  MW: "말라위",
  MY: "말레이지아",
  ML: "말리",
  MT: "몰타",
  MQ: "프랑스(마르티니크섬)",
  MX: "멕시코",
  MC: "모나코",
  MA: "모로코",
  MU: "모리셔스",
  MR: "모리타니",
  MZ: "모잠비크",
  MS: "영국(몽세라)",
  MD: "몰도바",
  MV: "몰디브",
  MN: "몽고",
  US: "미국",
  VI: "미국(버진제도)",
  AS: "미국(사모아제도)",
  MM: "미얀마",
  VU: "바누아투",
  BH: "바레인",
  BB: "바베이도스",
  BS: "바하마",
  BD: "방글라데시",
  BY: "벨라루스",
  BM: "영국(버뮤다섬)",
  VE: "베네수엘라",
  BJ: "베넹",
  VN: "베트남",
  BE: "벨기에",
  BZ: "벨리세",
  BA: "보스니아헤르체코비나",
  BW: "보츠와나",
  BO: "볼리비아",
  BF: "부르키나파소",
  BT: "부탄",
  MP: "미국(사이판)",
  BG: "불가리아",
  BR: "브라질",
  BN: "브루나이",
  BI: "브룬디",
  WS: "미국(사모아, 구 서사모아)",
  SA: "사우디아라비아",
  CY: "사이프러스",
  SM: "산마리노",
  SN: "세네갈",
  SC: "세이셸",
  LC: "세인트루시아",
  VC: "세인트빈센트그레나딘",
  KN: "세인트키츠네비스",
  SB: "솔로몬아일란드",
  SR: "수리남",
  LK: "스리랑카",
  SZ: "스와질랜드",
  SE: "스웨덴",
  CH: "스위스",
  ES: "스페인",
  SK: "슬로바키아",
  SI: "슬로베니아",
  SL: "시에라리온",
  SG: "싱가포르",
  AE: "아랍에미레이트연합국",
  AW: "네덜란드(아루바섬)",
  AM: "아르메니아",
  AR: "아르헨티나",
  IS: "아이슬란드",
  HT: "아이티",
  IE: "아일랜드",
  AZ: "아제르바이잔",
  AF: "아프가니스탄",
  AI: "영국(안귈라섬)",
  AD: "안도라",
  AG: "앤티과바부다",
  AL: "알바니아",
  DZ: "앙골라",
  ER: "에리트리아",
  EE: "에스토니아",
  EC: "에콰도르",
  SV: "엘살바도르",
  GB: "영국",
  VG: "영국(버진제도)",
  YE: "예멘",
  OM: "오만",
  AU: "오스트레일리아(호주)",
  AT: "오스트리아",
  HN: "온두라스",
  JO: "요르단",
  UG: "우간다",
  UY: "우루과이",
  UZ: "우즈베크",
  UA: "우크라이나",
  ET: "이라크",
  IR: "이란",
  IL: "이스라엘",
  EG: "이집트",
  IT: "이탈리아",
  IN: "인도",
  ID: "인도네시아",
  JP: "일본",
  JM: "자메이카",
  ZM: "잠비아",
  CN: "중국",
  MO: "중국",
  HK: "중국",
  CF: "중앙아프리카",
  DJ: "지부티",
  GI: "영국(지브롤터)",
  ZW: "짐바브웨",
  TD: "차드",
  CZ: "체코",
  CS: "체코슬로바키아",
  CL: "칠레",
  CA: "캐나다",
  CM: "카메룬",
  CV: "카보베르데",
  KY: "영국(케이만제도)",
  KZ: "카자흐",
  QA: "카타르",
  KH: "캄보디아",
  KE: "케냐",
  CR: "코스타리카",
  CI: "코트디봐르",
  CO: "콜롬비아",
  CG: "콩고",
  CU: "쿠바",
  KW: "쿠웨이트",
  HR: "크로아티아",
  KG: "키르키즈스탄",
  TJ: "타지키스탄",
  TZ: "탄자니아",
  TH: "태국",
  TC: "영국(터크스케이코스제도)",
  TG: "토고",
  TR: "터키",
  TO: "통가",
  TV: "투발루",
  TN: "튀니지",
  TT: "트리니다드토바고",
  PA: "파나마",
  PY: "파라과이",
  PK: "파키스탄",
  PG: "파푸아뉴기니",
  PW: "미국(팔라우섬)",
  FO: "덴마크(페로즈제도)",
  PE: "페루",
  PT: "포르투갈",
  PL: "폴란드",
  PR: "미국(푸에르토리코섬)",
  FR: "프랑스",
  GF: "프랑스(기아나)",
  PF: "프랑스(폴리네시아)",
  FJ: "피지",
  FI: "핀란드",
  PH: "필리핀",
  HU: "헝가리",
  KR: "한국",
  ALL: "ALL",
};
/**
 * 주어진 키에 해당하는 한국 국가 코드를 반환하는 함수입니다.
 *
 * @param key - 국가 코드를 찾기 위한 키 값입니다.
 * @returns 주어진 키에 해당하는 한국 국가 코드입니다.
 *
 * @remarks 주어진 키가 유효하지 않은 경우 undefined를 반환할 수 있습니다.
 */
export const getCountryCodeKR = (key: string) => {
  return code[key];
};
