import { atom } from "recoil";

const IS_LOADED_WITHDRAWAL_TEMPLATE_DATA = atom<boolean>({
  key: "forwarding/adminTrello/atoms/IS_LOADED_WITHDRAWAL_TEMPLATE_DATA",
  default: false,
});

const TRELLO_SALES_MANAGEMENT_DEPOSIT_AMOUNT = atom<number>({
  key: "forwarding/adminTrello/atoms/TRELLO_SALES_MANAGEMENT_DEPOSIT_AMOUNT",
  default: 0,
});

export default {
  IS_LOADED_WITHDRAWAL_TEMPLATE_DATA,
  TRELLO_SALES_MANAGEMENT_DEPOSIT_AMOUNT,
};
