import { GET_CUSTOMS_LIST_RES } from "../../../api-interfaces/shipda-api/customs";

export const CUSTOMS_LIST: GET_CUSTOMS_LIST_RES = {
  total: 5,
  list: [
    {
      id: 1,
      email: "aaa@gmail.com",
      phone: "0335555666",
      company: {
        name: "원영관세사",
      },
    },
  ],
};
