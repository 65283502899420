import * as shipdaAdminOrderHandlers from "../shipda-admin-api/order/handlers";
import * as shipdaAdminPromotionHandlers from "../shipda-admin-api/promotion/handlers";
import * as shipdaAdminSettlementHandlers from "../shipda-admin-api/settlement/handlers";
import * as shipdaAdminTeamHandlers from "../shipda-admin-api/team/handlers";
import * as shipdaAdminTrelloBidHandlers from "../shipda-admin-api/trello/handlers";
import * as shipdaAdminBidUserHandlers from "../shipda-admin-api/user/handlers";

export default {
  ...shipdaAdminTrelloBidHandlers,
  ...shipdaAdminPromotionHandlers,
  ...shipdaAdminBidUserHandlers,
  ...shipdaAdminOrderHandlers,
  ...shipdaAdminTeamHandlers,
  ...shipdaAdminSettlementHandlers,
};
