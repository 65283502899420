import { rest } from "msw";

import { getBofulDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import { DELETE_SINGLE_SKU_FAILURE, GET_GROUP_SEARCH_LIST } from "./data";

export const getShippingTrackingInfoOfParcel = rest.get(
  getBofulDevAPI("/returning/user/tracking/parcel"),

  (req, res, ctx) => {
    return res(
      ctx.status(404),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ code: 404 })
    );
  }
);

export const getGroupSkuSearchList = rest.get(
  getBofulDevAPI("/group/search"),
  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_GROUP_SEARCH_LIST)
    )
);

export const deleteSingleSku = rest.delete(
  getBofulDevAPI("/sku"),
  (req, res, ctx) =>
    res(
      ctx.status(422),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(DELETE_SINGLE_SKU_FAILURE)
    )
);
