import { Trans } from "react-i18next";
import * as EmailValidator from "email-validator";

import { TransType } from "@sellernote/_shared/src/types/common/i18n";
import regEx from "@sellernote/_shared/src/utils/common/regEx";

import { UPS_AND_FEDEX_ALLOW_CHARACTER_LIST } from "../../constants/fulfillment/shipping";
export interface ValidationResult {
  result: boolean;
  message?: React.ReactNode;
}

/**
 * HS 코드를 유효성 검사하는 함수
 *
 * @param val - 검사할 HS 코드 문자열
 * @returns HS 코드가 유효하면 true, 그렇지 않으면 false
 */
export function isValidHsCode(val?: string) {
  if (!(val && val.length === 12)) {
    return false;
  }

  const arr = val.split("");

  const isValidated = arr.every((v, i) => {
    if (i === 4 || i === 7) {
      return v === "-";
    }

    return Number.isInteger(Number(v));
  });

  return isValidated;
}

/**
 * 문자열에 중국어가 포함되어 있는지 확인하는 함수
 *
 * @param val - 검사할 문자열
 * @returns 문자열에 중국어가 포함되어 있으면 true, 그렇지 않으면 false
 */
export function hasChinese(val: string) {
  const result = /[\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/.test(
    val
  );
  return result;
}

/**
 * 문자열에 영어가 포함되어 있는지 확인
 *
 * @param val - 검사할 문자열
 * @returns 문자열에 영어가 포함되어 있으면 true, 그렇지 않으면 false
 */
export function hasEnglish(val: string) {
  const result = /[a-zA-Z]/i.test(val);
  return result;
}

/**
 * xxxx-xx-xxxx(x는 정수)의 형식으로 포맷팅
 *
 * @param val - 포맷팅할 문자열
 * @returns 포맷팅된 문자열
 *
 * @remarks 입력된 문자열이 유효하지 않으면 빈 문자열을 반환합니다.
 */
export function filterForHsCode(val: string) {
  let arr = val.split("");

  if (arr[4] && arr[4] !== "-") {
    arr = insertDivider(arr, 4);
  }

  if (arr[7] && arr[7] !== "-") {
    arr = insertDivider(arr, 7);
  }

  if (arr.length > 12) {
    arr = arr.slice(0, 12);
  }

  const isValidated = arr.every((v, i) => {
    if (i === 4 || i === 7) {
      return v === "-";
    }

    return Number.isInteger(Number(v));
  });
  if (!isValidated) {
    arr = [];
  }

  const result = arr.join("");

  return result;

  function insertDivider(originArr: string[], position: number) {
    const beforeArr = originArr.slice(0, position);
    const afterArr = originArr.slice(position);
    const result = [...beforeArr, "-", ...afterArr];

    return result;
  }
}

/**
 * 문자열이 공백인지 확인
 *
 * @param str - 검사할 문자열
 * @returns 문자열이 공백이면 true, 그렇지 않으면 false
 */
export function isWhiteSpace(str?: any) {
  if (!str || typeof str !== "string") {
    return false;
  }

  return str.trim() ? false : true;
}

/**
 * 문자열에서 숫자만 추출
 *
 * @param v - 변환할 문자열
 * @returns 숫자만 포함된 문자열
 */
export function getValueAsNumberOnly(v?: string) {
  if (!v) return "";

  const regExrNumber = /[^0-9]/gi;

  return v.replace(regExrNumber, "");
}

/**
 * 문자열에서 숫자와 영문자만 추출
 *
 * @param v - 변환할 문자열
 * @returns 숫자와 영문자만 포함된 문자열
 */
export function getValueAsNumberOrLetter(v?: string) {
  if (!v) return "";

  const regExr = /[^A-Za-z0-9]/gi;

  return v.replace(regExr, "");
}

interface ValidateLengthFunc {
  ({
    value,
    minLength,
    maxLength,
  }: {
    value: string;
    minLength?: number;
    maxLength: number;
  }): boolean;
}

/**
 * 문자열의 길이를 검증하는 함수
 *
 * @param value - 검사할 문자열
 * @param minLength - 최소 길이 (기본값: 0)
 * @param maxLength - 최대 길이
 * @returns 문자열의 길이가 유효하면 true, 그렇지 않으면 false
 *
 * @remarks minLength와 maxLength는 0 이상이어야 하며, minLength는 maxLength보다 작아야 합니다.
 */
export const validateLength: ValidateLengthFunc = ({
  value,
  minLength = 0,
  maxLength,
}) => {
  if (minLength < 0 || maxLength < 0) return false;
  if (minLength === maxLength) return false;
  if (minLength > maxLength) return false;

  return minLength <= value.length && value.length <= maxLength;
};

/**
 * 문자열의 길이를 검증하는 함수
 *
 * @param value - 검사할 문자열
 * @param maxLength - 최대 길이
 * @returns 문자열의 길이가 유효하면 true, 그렇지 않으면 false
 */
export function getStringLengthValidation({
  value,
  maxLength,
}: {
  value?: string;
  maxLength: number;
}): ValidationResult {
  if (value && !validateLength({ value, maxLength })) {
    return {
      result: false,
      message: `${maxLength}자를 넘었습니다.`,
    };
  }

  return {
    result: true,
  };
}

/**
 * 지원자 이름의 길이를 검증하는 함수
 *
 * @param value - 검사할 문자열
 * @returns 문자열의 길이가 유효하면 true, 그렇지 않으면 false
 */
export function getApplicantNameValidation(value?: string): ValidationResult {
  if (value && !validateLength({ value, maxLength: 36 })) {
    return {
      result: false,
      message: "36자 이하로 입력해주세요.",
    };
  }

  return {
    result: true,
  };
}

/**
 * 반품 요청 사항의 길이를 검증하는 함수
 *
 * @param value - 검사할 문자열
 * @returns 문자열의 길이가 유효하면 true, 그렇지 않으면 false
 */
export function getReturningRequestsValidation(
  value?: string
): ValidationResult {
  if (value && !validateLength({ value, maxLength: 255 })) {
    return {
      result: false,
      message: "255자 이하로 입력해주세요.",
    };
  }

  return {
    result: true,
  };
}

/**
 * 상세 주소의 길이를 검증하는 함수
 *
 * @param value - 검사할 문자열
 * @returns 문자열의 길이가 유효하면 true, 그렇지 않으면 false
 */
export function getDetailAddressValidation(value?: string): ValidationResult {
  if (value && !validateLength({ value, maxLength: 255 })) {
    return {
      result: false,
      message: "255자 이하로 입력해주세요.",
    };
  }

  return {
    result: true,
  };
}

/**
 * 전화번호의 길이를 검증하는 함수
 *
 * @param value - 검사할 문자열
 * @returns 문자열의 길이가 유효하면 true, 그렇지 않으면 false
 */
export function getPhoneNumberValidation(value?: string): ValidationResult {
  if (value && !validateLength({ value, minLength: 9, maxLength: 12 })) {
    return {
      result: false,
      message: "연락처 형식이 올바르지 않습니다.",
    };
  }

  return {
    result: true,
  };
}

/**
 * 국내 배송 전화번호의 길이를 검증하는 함수
 *
 * @param value - 검사할 문자열
 * @returns 문자열의 길이가 유효하면 true, 그렇지 않으면 false
 */
export function getShippingDomesticPhoneNumberValidation(
  value?: string
): ValidationResult {
  if (value && !/^[\d]{8,12}$/gi.test(value)) {
    return {
      result: false,
      message: "8자리 이상 12자리 이하 숫자만 입력 가능합니다.",
    };
  }

  return {
    result: true,
  };
}

/**
 * 영문 이름의 형식을 검증하는 함수
 *
 * @param value - 검사할 문자열
 * @returns 문자열의 형식이 유효하면 true, 그렇지 않으면 false
 */
export function getEnglishNameValidation(value?: string): ValidationResult {
  if (value && !/^[a-zA-Z0-9\s]+$/.test(value)) {
    return {
      result: false,
      message: "이름 형식이 올바르지 않습니다.",
    };
  }

  return {
    result: true,
  };
}

/**
 * 해외 주문 번호의 형식을 검증하는 함수
 *
 * @param value - 검사할 문자열
 * @returns 문자열의 형식이 유효하면 true, 그렇지 않으면 false
 */
export function getOverseasOrderNumberValidation(
  value?: string
): ValidationResult {
  const allowedList = UPS_AND_FEDEX_ALLOW_CHARACTER_LIST.map(
    (c) => `\\${c}`
  ).join("");
  if (
    value &&
    !new RegExp(`^[a-zA-Z0-9\\s${allowedList}]+$`, "g").test(value)
  ) {
    return {
      result: false,
      message: "주문 번호 형식이 올바르지 않습니다.",
    };
  }

  return {
    result: true,
  };
}

/**
 * 운송장 번호의 형식을 검증하는 함수
 *
 * @param value - 검사할 문자열
 * @param allowEmpty - 빈 문자열을 허용할지 여부
 * @returns 문자열의 형식이 유효하면 true, 그렇지 않으면 false
 *
 * @remarks allowEmpty가 false인 경우, value가 빈 문자열이면 유효하지 않습니다.
 */
export function getInvoiceNoValidation({
  value,
  allowEmpty,
}: {
  value?: string;
  allowEmpty?: boolean;
}): ValidationResult {
  if (!allowEmpty && !value) {
    return {
      result: false,
      message: "운송장 번호를 입력해주세요.",
    };
  }

  if (value && !validateLength({ value, minLength: 8, maxLength: 24 })) {
    return {
      result: false,
      message: "운송장 형식에 맞게 입력해주세요. (예: 123456780001)",
    };
  }

  return {
    result: true,
  };
}

/**
 * 주어진 이메일 주소가 유효한지 검증하는 함수입니다.
 *
 * @param value - 검증할 이메일 주소 문자열입니다. 선택적 인수로, 값이 없을 경우 기본적으로 유효한 것으로 간주합니다.
 * @return {ValidationResult} 이메일 주소가 유효한지 여부와 메시지를 포함한 객체를 반환합니다.
 *
 * @remarks
 * 이메일 주소가 유효하지 않은 경우, `result`는 `false`이고, `message`는 오류 메시지를 포함합니다.
 */
export function getEmailValidation(value?: string): ValidationResult {
  const regExrEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (value && !regExrEmail.test(value)) {
    return {
      result: false,
      message: <Trans i18nKey="shared:이메일_형식_오류" />,
    };
  }

  return {
    result: true,
  };
}

/**
 * 이메일 주소의 유효성을 검사하는 함수
 *
 * @param val - 검사할 이메일 주소 문자열
 * @param allowEmpty - 빈 문자열을 허용할지 여부
 * @param Trans - 다국어 번역을 위한 함수
 * @returns 이메일 주소가 유효하면 true, 그렇지 않으면 false를 포함한 ValidationResult 객체
 *
 * @remarks
 * allowEmpty가 false인 경우, val이 빈 문자열이면 유효하지 않습니다.
 */
export function validateEmail({
  val,
  allowEmpty,
  Trans,
}: {
  val?: string;
  allowEmpty?: boolean;
  Trans: TransType;
}): ValidationResult {
  if (!allowEmpty && !val) {
    return {
      result: false,
      message: <Trans i18nKey="utils:validation.emailGuide" />,
    };
  }

  if (val && !EmailValidator.validate(val)) {
    return {
      result: false,
      message: <Trans i18nKey="utils:validation.invalideEmail" />,
    };
  }

  return {
    result: true,
  };
}

/**
 * 한국 또는 싱가폴 핸드폰 번호를 유효성 검사하는 함수
 *
 * @param phoneNumber - 검사할 핸드폰 번호 문자열
 * @returns 핸드폰 번호가 유효하면 true, 그렇지 않으면 false
 */
export function validateRegExPhone(phoneNumber: string) {
  return regEx.phone.test(phoneNumber);
}

/**
 * 한국 또는 싱가폴 핸드폰 번호의 글자 수를 검사하는 함수
 *
 * @param phoneNumber - 검사할 핸드폰 번호 문자열
 * @returns 핸드폰 번호의 글자 수가 유효하면 true, 그렇지 않으면 false
 */
export function validatePhoneLength(phoneNumber: string) {
  return 8 < phoneNumber.length && phoneNumber.length < 12;
}

/**
 * 핸드폰 번호에 하이픈이 포함된 경우 유효성 검사하는 함수
 *
 * @param phoneNumber - 검사할 핸드폰 번호 문자열
 * @returns 핸드폰 번호가 유효하면 true, 그렇지 않으면 false
 */
export function validatePhoneNumberWithHyphen(phoneNumber: string) {
  const numberAndHyphenRegex = /^[0-9-]*$/;

  return numberAndHyphenRegex.test(phoneNumber);
}

/**
 * 핸드폰 번호를 유효성 검사하는 함수
 *
 * @param val - 검사할 핸드폰 번호 문자열
 * @param allowEmpty - 빈 문자열을 허용할지 여부
 * @param allowHyphen - 하이픈을 허용할지 여부
 * @param Trans - 다국어 번역을 위한 함수
 * @returns 핸드폰 번호가 유효하면 true, 그렇지 않으면 false를 포함한 ValidationResult 객체
 *
 * @remarks
 * allowEmpty가 false인 경우, val이 빈 문자열이면 유효하지 않습니다.
 */
export function validatePhone({
  val,
  allowEmpty,
  allowHyphen = false,
  Trans,
}: {
  val?: string;
  allowEmpty?: boolean;
  allowHyphen?: boolean;
  Trans: TransType;
}): ValidationResult {
  if (!allowEmpty && !val && !allowHyphen) {
    return {
      result: false,
      message: <Trans i18nKey="shared:연락처_입력_요청" />,
    };
  }

  if (
    val &&
    !validateRegExPhone(val) &&
    !validatePhoneLength(val) &&
    !allowHyphen
  ) {
    return {
      result: false,
      message: <Trans i18nKey="shared:연락처_형식_오류" />,
    };
  }

  if (val && allowHyphen && !validatePhoneNumberWithHyphen(val)) {
    return {
      result: false,
      message: <Trans i18nKey="shared:연락처_예외입력_오류" />,
    };
  }

  return {
    result: true,
  };
}

/**
 * 영문 회사명을 유효성 검사하는 함수
 *
 * @param val - 검사할 회사명 문자열
 * @returns 회사명이 유효하면 true, 그렇지 않으면 false를 포함한 객체
 */
export function validateEnglishCompanyName(val: string) {
  if (val && !regEx.engOrNumberOrBlankOrSpecial.test(val)) {
    return {
      isValid: false,
      errorMessage: <Trans i18nKey="shared:회사명_영문_미허용_입력_오류" />,
    };
  }

  return {
    isValid: true,
  };
}

/**
 * SG쉽다 사업자등록증(UEN) 유효성 검사 함수
 *
 * @param val - 검사할 UEN 문자열
 * @returns UEN이 유효하면 true, 그렇지 않으면 false를 포함한 객체
 */
export const validateUEN = (val: string) => {
  if (!val) {
    return {
      isValid: true,
      errorMessage: "",
    };
  }

  const isValidLength = val.length === 9 || val.length === 10;

  // 뒤에서 2번째 ~ 5번째 자리가 숫자인지 확인
  const isValidFormat = val
    .slice(-5, -1)
    .split("")
    .every((char) => !isNaN(parseInt(char, 10)));

  if (!isValidLength || !isValidFormat) {
    return {
      isValid: false,
      errorMessage: "The UEN format is incorrect.",
    };
  }

  return {
    isValid: true,
    errorMessage: "",
  };
};

/**
 * 공통 비밀번호 유효성 검사 함수
 *
 * @param password - 검사할 비밀번호 문자열
 * @param Trans - 다국어 번역을 위한 함수
 * @returns 비밀번호가 유효하면 true, 그렇지 않으면 false를 포함한 객체
 */
export const validateCommonPassword = ({
  password,
  Trans,
}: {
  password: string;
  Trans: TransType;
}) => {
  if (password && password.length < 6)
    return {
      isValid: false,
      errorMessage: <Trans i18nKey="shared:비밀번호_길이_오류" />,
    };

  return {
    isValid: true,
  };
};

/**
 * 새로운 비밀번호 유효성 검사 함수
 *
 * @param currentPassword - 현재 비밀번호 문자열
 * @param newPassword - 새로운 비밀번호 문자열
 * @param Trans - 다국어 번역을 위한 함수
 * @returns 새로운 비밀번호가 유효하면 true, 그렇지 않으면 false를 포함한 객체
 */
export const validateNewPassword = ({
  currentPassword,
  newPassword,
  Trans,
}: {
  currentPassword: string;
  newPassword: string;
  Trans: TransType;
}) => {
  if (newPassword && newPassword === currentPassword) {
    return {
      isValid: false,
      errorMessage: <Trans i18nKey="shared:비밀번호_기존비밀번호_동일_오류" />,
    };
  }

  if (newPassword && !regEx.password.test(newPassword)) {
    return {
      isValid: false,
      errorMessage: <Trans i18nKey="shared:비밀번호_특수문자_미포함_오류" />,
    };
  }

  return validateCommonPassword({ password: newPassword, Trans });
};

/**
 * 새로운 비밀번호 확인 유효성 검사 함수
 *
 * @param currentPassword - 현재 비밀번호 문자열
 * @param newPassword - 새로운 비밀번호 문자열
 * @param newPasswordConfirm - 새로운 비밀번호 확인 문자열
 * @param Trans - 다국어 번역을 위한 함수
 * @returns 새로운 비밀번호 확인이 유효하면 true, 그렇지 않으면 false를 포함한 객체
 */
export const validateNewPasswordConfirm = ({
  currentPassword,
  newPassword,
  newPasswordConfirm,
  Trans,
}: {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
  Trans: TransType;
}) => {
  if (currentPassword && !newPasswordConfirm) {
    return { isValid: false };
  }

  if (newPassword !== newPasswordConfirm) {
    return {
      isValid: false,
      errorMessage: <Trans i18nKey="shared:비밀번호_새비밀번호_확인_오류" />,
    };
  }

  return { isValid: true };
};

/**
 * 최대 값 이하인지 확인하는 함수
 *
 * @param value - 검사할 값 (문자열 또는 숫자)
 * @param maxValue - 최대 값
 * @returns 값이 최대 값 이하이면 true, 그렇지 않으면 false
 */
export function validateMaxValue({
  value,
  maxValue,
}: {
  value: string | number;
  maxValue: number;
}) {
  if (Number(parseFloat(value.toString())) > maxValue) return false;
  else return true;
}

/**
 * 소수점 두 자리 이상인지 확인하는 함수
 *
 * @param value - 검사할 값 (문자열 또는 숫자)
 * @returns 소수점 두 자리 이상이면 true, 그렇지 않으면 false
 */
export function checkHasTwoDecimalPlaces(value: string | number) {
  const twoDecimalPlaces = /^\d*[.]\d{3}$/;

  return twoDecimalPlaces.test(value.toString());
}

/**
 * 소수점 두 개 이상인지 확인하는 함수
 *
 * @param value - 검사할 값 (문자열 또는 숫자)
 * @returns 소수점 두 개 이상이면 true, 그렇지 않으면 false
 */
export function checkHasTwoDots(value: string | number) {
  const dots = /^\d*[.]\d*[.]\d*$/;

  return dots.test(value.toString());
}

/**
 * 소수점 가격 유효성 검사 함수
 *
 * @param value - 검사할 값 (문자열 또는 숫자)
 * @returns 소수점 가격이 유효하면 true, 그렇지 않으면 false
 */
export const validateDecimalPrice = (value: string | number) => {
  // 소수점 두 개 이상 입력 방지
  if (checkHasTwoDots(value)) return false;

  // 소수점 두 자리 이상 입력 방지
  if (checkHasTwoDecimalPlaces(value)) return false;

  return true;
};

/**
 * FOB 가격 유효성 검사 함수
 *
 * @param value - 검사할 값 (문자열 또는 숫자)
 * @returns FOB 가격이 유효하면 true, 그렇지 않으면 false
 */
export const validateFobPrice = (value: string | number) => {
  if (!validateDecimalPrice(value)) return false;

  // 최대값 999999999.99 이상 입력 방지
  if (
    !validateMaxValue({
      value: value,
      maxValue: 999999999.99,
    })
  )
    return false;

  return true;
};

/**
 * 수량 유효성 검사 함수
 *
 * @param count - 검사할 수량
 * @param maxCount - 최대 수량
 * @param minCount - 최소 수량 (기본값: 0)
 * @param message - 유효성 검사 실패 시 표시할 메시지 객체
 * @returns 수량이 유효하면 true, 그렇지 않으면 false를 포함한 객체
 */
export function validateCount({
  count,
  maxCount,
  minCount = 0,
  message,
}: {
  count?: number;
  maxCount: number;
  minCount?: number;
  message?: { minCount?: string; maxCount?: string };
}): ValidationResult {
  if (!count) {
    return {
      result: false,
    };
  }

  if (count > maxCount) {
    return {
      result: false,
      message: message?.maxCount ?? "입력 가능 수량 이하로 입력해주세요.",
    };
  }

  if (count < minCount) {
    return {
      result: false,
      message:
        message?.minCount ?? "최소 수량보다 낮은 수량으로 입력 불가능합니다.",
    };
  }

  return {
    result: true,
  };
}

/**
 * 문자열 길이 유효성 검사 함수
 *
 * @param value - 검사할 문자열
 * @param max - 최대 길이
 * @returns 문자열 길이가 유효하면 true, 그렇지 않으면 false를 포함한 객체
 */
export const validateValueLength = ({
  value,
  max,
}: {
  value: string | null | undefined;
  max: number;
}): ValidationResult => {
  if (value && value.length > max) {
    return {
      result: false,
      message: (
        <Trans
          i18nKey="shared:입력_글자수_초과"
          values={{
            max,
          }}
        />
      ),
    };
  }

  return {
    result: true,
  };
};

/**
 * 문자열에 선행 또는 후행 공백이 있는지 확인하는 함수
 *
 * @param value - 검사할 문자열
 * @returns 선행 또는 후행 공백이 있으면 true, 그렇지 않으면 false
 */
export const hasLeadingOrTrailingWhitespace = (value: string | undefined) => {
  if (!value) {
    return false;
  }

  return /^\s|\s$/.test(value);
};

/**
 * 영문, 하이픈, 공백 유효성 검사 함수
 *
 * @param inputValue - 검사할 문자열
 * @returns 영문, 하이픈, 공백만 포함되어 있으면 true, 그렇지 않으면 false
 */
export const validateEnglishWithHyphenAndWhiteSpace = (
  inputValue: string | undefined
) => {
  if (!inputValue) {
    return false;
  }
  // 영문 대/소문자, 공백, 그리고 "-"만 허용하도록 정규표현식을 사용합니다.
  const pattern = /^[a-zA-Z\s-]+$/;
  return pattern.test(inputValue);
};

/**
 * 영문과 숫자 유효성 검사 함수
 *
 * @param inputValue - 검사할 문자열
 * @returns 영문과 숫자만 포함되어 있으면 true, 그렇지 않으면 false
 */
export const validateEnglishAndNumber = (inputValue: string | undefined) => {
  if (!inputValue) {
    return false;
  }
  // 영문과 숫자만 허용한다.
  const onlyEnglishAndNumber = /^[a-zA-Z0-9]+$/;
  return onlyEnglishAndNumber.test(inputValue);
};

/**
 * 숫자 유효성 검사 함수
 *
 * @param inputValue - 검사할 문자열
 * @returns 숫자만 포함되어 있으면 true, 그렇지 않으면 false
 */
export const validateNumber = (inputValue: string | undefined) => {
  if (!inputValue) {
    return false;
  }
  // 숫자만 허용하는 정규표현식
  const numberRegex = /^[0-9]+$/;

  return numberRegex.test(inputValue);
};

/**
 * 값이 null인지 확인하는 함수
 *
 * @param value - 검사할 값
 * @returns 값이 null이면 true, 그렇지 않으면 false
 */
export const checkIsNull = (value: unknown) => value === null;

/**
 * 값이 null이 아닌지 확인하는 함수
 *
 * @param value - 검사할 값
 * @returns 값이 null이 아니면 true, 그렇지 않으면 false
 */
export const checkIsNotNull = (value: unknown) => !checkIsNull(value);

/**
 * 수량, 중량, 길이 등 숫자 입력 시 유효성 검사 함수
 *
 * @param min - 최소 값 (초과)
 * @param max - 최대 값 (이하)
 * @param value - 검사할 값 (선택적 인수)
 * @returns 값이 유효하면 true, 그렇지 않으면 false
 */
export const validMinMaxNumber = (min: number, max: number, value?: number) =>
  value && value > min && value <= max;
