import { useAppQueryWithQueryKeyFactory } from "../../services/query";

import {
  GET_DASHBOARD_DATA_RES,
  GET_OVERALL_TRACKING_DATA_RES,
} from "../../api-interfaces/shipda-api/adminDashboard";

export const ADMIN_DASHBOARD_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_DASHBOARD_QUERY" }] as const,

  getDashboardData: () =>
    [
      {
        ...ADMIN_DASHBOARD_QUERY_KEY_GEN.all()[0],
        entity: "getDashboardData",
      },
    ] as const,

  getOverallTracking: () =>
    [
      {
        ...ADMIN_DASHBOARD_QUERY_KEY_GEN.all()[0],
        entity: "getOverallTracking",
      },
    ] as const,
};

function useGetDashboardData() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_DASHBOARD_QUERY_KEY_GEN.getDashboardData>,
    GET_DASHBOARD_DATA_RES
  >({
    queryKey: ADMIN_DASHBOARD_QUERY_KEY_GEN.getDashboardData(),
    requestOptions: {
      method: "get",
      path: `/statistics/dash`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useGetOverallTracking() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_DASHBOARD_QUERY_KEY_GEN.getOverallTracking>,
    GET_OVERALL_TRACKING_DATA_RES[]
  >({
    queryKey: ADMIN_DASHBOARD_QUERY_KEY_GEN.getOverallTracking(),
    requestOptions: {
      method: "get",
      path: `/tracking/overall`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

const ADMIN_DASHBOARD_QUERY = {
  useGetDashboardData,
  useGetOverallTracking,
};

export default ADMIN_DASHBOARD_QUERY;
