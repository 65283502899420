import { rest } from "msw";

import { getBofulDevAPI } from "../../../services/msw";

import { INTEGRATE_PLAYAUTO_REQ } from "../../../api-interfaces/boful-api/order";
import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import {
  CONFIRM_OAUTH_CAFE24_RESULT,
  GET_LINKED_SHIPPING_MALL_LIST_TEST,
  GET_SELLER_CREDENTIAL_LINKED_DETAIL,
  GET_SELLER_CREDENTIAL_LIST,
  GET_SELLER_CREDENTIAL_UNLINKED_DETAIL,
  GET_SELLER_ORDER,
  GET_SELLER_ORDER_ID,
  GET_SELLER_SKU_LIST,
  INTEGRATE_PLAYAUTO_ALL_KEY_400,
  INTEGRATE_PLAYAUTO_API_KEY_400,
  INTEGRATE_PLAYAUTO_AUTHENTICATION_KEY_400,
  LINK_SELLER_CREDENTIAL,
  UNLINK_SELLER_CREDENTIAL,
  UPDATE_SELLER_CREDENTIAL,
} from "./data";

export const requestSellerCredential = rest.post(
  getBofulDevAPI("/seller-credential"),
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json({}));
  }
);

export const getSellerCredentialList = rest.get(
  getBofulDevAPI("/seller-credential"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_SELLER_CREDENTIAL_LIST)
    );
  }
);

export const getSellerCredentialDetail = rest.get(
  getBofulDevAPI("/seller-credential/:id"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(
        req.params.id === 1
          ? GET_SELLER_CREDENTIAL_UNLINKED_DETAIL
          : GET_SELLER_CREDENTIAL_LINKED_DETAIL
      )
    );
  }
);

export const integratePlayauto = rest.post(
  getBofulDevAPI(`/seller-credential/playauto/authKey`),
  (req, res, ctx) => {
    const reqBody = req.body as INTEGRATE_PLAYAUTO_REQ;
    const testApiKey = reqBody.authKeys["apiKey"] || "";
    const testAuthenticationKey = reqBody.authKeys["authenticationKey"] || "";

    if (testApiKey === "400-api-key") {
      return res(
        ctx.status(400),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(INTEGRATE_PLAYAUTO_API_KEY_400)
      );
    }

    if (testAuthenticationKey === "400-authentication-key") {
      return res(
        ctx.status(400),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(INTEGRATE_PLAYAUTO_AUTHENTICATION_KEY_400)
      );
    }

    if (
      testApiKey === "400-all-key" &&
      testAuthenticationKey === "400-all-key"
    ) {
      return res(
        ctx.status(400),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(INTEGRATE_PLAYAUTO_ALL_KEY_400)
      );
    }

    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ result: "OK" })
    );
  }
);

export const unLinkSellerCredential = rest.delete(
  getBofulDevAPI("/seller-credential/:id"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(UNLINK_SELLER_CREDENTIAL)
    );
  }
);

export const linkSellerCredential = rest.post(
  getBofulDevAPI("/seller-credential"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(LINK_SELLER_CREDENTIAL)
    );
  }
);

export const updateSellerCredential = rest.put(
  getBofulDevAPI("/seller-credential/:id"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(UPDATE_SELLER_CREDENTIAL)
    );
  }
);

export const getSellerCredentialAuthKey = rest.get(
  getBofulDevAPI("/seller-credential/:mall/authKey"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        vendorId: "",
        apiKey: "",
        secretKey: "",
      })
    );
  }
);

export const getSellerSKUList = rest.get(
  getBofulDevAPI("/seller-sku"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_SELLER_SKU_LIST)
    );
  }
);

export const updateSellerSKUList = rest.post(
  getBofulDevAPI("/seller-sku/update"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        result: "ok",
      })
    );
  }
);

export const linkSellerSKU = rest.patch(
  getBofulDevAPI("/seller-sku/link/:id"),
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json({}));
  }
);

export const unLinkSellerSKU = rest.delete(
  getBofulDevAPI("/seller-sku/:id"),
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json({}));
  }
);

export const getLinkShoppingMallList = rest.get(
  getBofulDevAPI("/fulfillment/:userId"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_LINKED_SHIPPING_MALL_LIST_TEST)
    );
  }
);

export const getConfirmOAuthCafe24 = rest.post(
  getBofulDevAPI("/seller-credential/integration/cafe24"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(CONFIRM_OAUTH_CAFE24_RESULT)
    );
  }
);

export const getSellerOrder = rest.get(
  getBofulDevAPI("/seller-order"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(
        GET_SELLER_ORDER(
          Number(req.url.searchParams.get("page")),
          Number(req.url.searchParams.get("perPage") || 10)
        )
      )
    );
  }
);

export const getSellerOrderId = rest.get(
  getBofulDevAPI("/seller-order/:id"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_SELLER_ORDER_ID(req.params.id))
    );
  }
);

export const updateSellerOrderWith429 = rest.post(
  getBofulDevAPI("/seller-order"),
  (req, res, ctx) => {
    return res(
      ctx.status(429),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        retryAfter: Number((Math.random() * 300).toFixed(0)),
      })
    );
  }
);

export const updateSellerOrderWith200 = rest.post(
  getBofulDevAPI("/seller-order"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        result: "ok",
      })
    );
  }
);
