import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../services/query";

import {
  CHANGE_CUSTOMS_ACTIVATE_FLAG_REQ,
  CHANGE_CUSTOMS_ACTIVATE_FLAG_REQ_PARAMS,
  CHANGE_CUSTOMS_ACTIVATE_FLAG_RES,
  GET_CUSTOMS_PARTNER_LIST_REQ,
  GET_CUSTOMS_PARTNER_LIST_RES,
} from "../../api-interfaces/shipda-api/adminCustomsPartner";

export const CUSTOMS_PARTNER_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/CUSTOMS_BID_QUERY" }] as const,

  getCustomsPartnerList: (params: GET_CUSTOMS_PARTNER_LIST_RES) =>
    [
      {
        ...CUSTOMS_PARTNER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "customsPartnerList",
      },
    ] as const,
};

function useGetCustomsPartnerList(params: GET_CUSTOMS_PARTNER_LIST_RES) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof CUSTOMS_PARTNER_QUERY_KEY_GEN.getCustomsPartnerList>,
    GET_CUSTOMS_PARTNER_LIST_REQ
  >({
    queryKey: CUSTOMS_PARTNER_QUERY_KEY_GEN.getCustomsPartnerList(params),
    requestOptions: {
      method: "get",
      path: `/partner/customs`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useChangeCustomsActivateFlag() {
  const mutation = useAppMutation<
    CHANGE_CUSTOMS_ACTIVATE_FLAG_REQ,
    CHANGE_CUSTOMS_ACTIVATE_FLAG_RES,
    CHANGE_CUSTOMS_ACTIVATE_FLAG_REQ_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ managerId }) => `/partner/customsAdmin/${managerId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

const CUSTOMS_PARTNER_QUERY = {
  useGetCustomsPartnerList,
  useChangeCustomsActivateFlag,
};

export default CUSTOMS_PARTNER_QUERY;
