import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";
import {
  MockedRequest,
  ResponseComposition,
  rest,
  RestContext,
  SetupWorkerApi,
} from "msw";

import useLocalStorage from "../../hooks/common/useLocalStorage";
import {
  getHandlersToReset,
  printMessageWithActiveHandlers,
} from "../../services/msw";

import { Handler } from "./useHandlerData";

export type MSWToolMode = "normal" | "error";

export default function useChangeAllHandlers({
  workerRef,
  handlerData,
  setHandlerData,
}: {
  workerRef: MutableRefObject<SetupWorkerApi | undefined>;
  handlerData: Handler[];
  setHandlerData: Dispatch<SetStateAction<Handler[]>>;
}) {
  const [mode, setMode] = useLocalStorage<MSWToolMode>("mswMode", "normal");

  const handleModeToggle = useCallback(() => {
    setMode((prevMode) => (prevMode === "normal" ? "error" : "normal"));
  }, [setMode]);

  useEffect(() => {
    if (mode === "normal") {
      workerRef.current?.resetHandlers(...getHandlersToReset(handlerData));

      printMessageWithActiveHandlers(workerRef, "Normal Mode");
    } else {
      workerRef.current?.use(
        ...["get", "post", "put", "patch", "delete"].map((method) =>
          (rest as any)[method](
            "*",
            (
              req: MockedRequest<any>,
              res: ResponseComposition<any>,
              ctx: RestContext
            ) => {
              return res(
                ctx.status(500),
                ctx.delay(500),
                ctx.json({
                  code: 500,
                  error: "Fail to something",
                })
              );
            }
          )
        )
      );

      printMessageWithActiveHandlers(workerRef, "Error Mode");
    }
  }, [handlerData, mode, workerRef]);

  const deactivateAllHandlers = useCallback(() => {
    setHandlerData((prevData) => {
      const newData = [...prevData];
      newData.forEach((item) => (item.active = false));

      return newData;
    });

    printMessageWithActiveHandlers(workerRef, "Deactivate all handlers");
  }, [setHandlerData, workerRef]);

  return { mode, handleModeToggle, deactivateAllHandlers };
}
