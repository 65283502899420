import { useEffect, useRef } from "react";
import { drop } from "@mswjs/data";
import { setupWorker, SetupWorkerApi } from "msw";
import { setupServer } from "msw/node";

import { IS_ON_BROWSER } from "../../constants";
import { DB } from "../db";

export default function useSetting() {
  const workerRef = useRef<SetupWorkerApi>();

  useEffect(() => {
    function initMockAPI() {
      if (IS_ON_BROWSER) {
        const worker = setupWorker();
        worker.start({ onUnhandledRequest: "bypass" });

        // https://mswjs.io/docs/extensions/life-cycle-events#responsemocked
        worker.events.on("response:mocked", () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          drop(DB);
        });

        workerRef.current = worker;
      } else {
        const server = setupServer();
        server.listen({ onUnhandledRequest: "bypass" });
      }
    }

    initMockAPI();
  }, []);

  return { workerRef };
}
