import { rest } from "msw";

import { getLocalPrinterAPI } from "../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../constants";

export const print = rest.post(getLocalPrinterAPI(), (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.delay(ARTIFICIAL_DELAY_MS),
    ctx.json({
      RESULT_CD: "1",
      RESULT_MSG: "Print Success.",
      PRINTER_NAME: "ZDesigner GT800 (EPL)",
    })
  );
});
