import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import { FulfillmentParcelCompany } from "../../types/fulfillment/fulfillment";

/**
 * 긴 문자열을 축약
 *
 * @param src - 원본 문자열
 * @param maxLength - 축약할 최대 길이
 * @param ellipsis - 축약 후 추가할 생략 문자
 * @returns 축약된 문자열
 */
function omitWithEllipsis({
  src,
  maxLength,
  ellipsis,
}: {
  src?: string;
  maxLength: number;
  ellipsis: string;
}): string {
  if (!src) {
    return "";
  }
  //string이 NFD(mac 방식)으로 있어 한글 length를 ㅁ,ㅏ으로 판별해 NFC로 변환해서 전달
  const NFCString = src.normalize("NFC");

  if (NFCString.length <= maxLength) {
    return NFCString;
  }

  const sliced = NFCString.slice(0, maxLength);

  return `${sliced} ${ellipsis}`;
}

/**
 * 문자열의 앞뒤 공백을 제거
 *
 * @param str - 원본 문자열
 * @returns 공백이 제거된 문자열
 */
function getTrimmedValue(str?: any) {
  if (!str || typeof str !== "string") {
    return str;
  }

  return str.trim();
}

/**
 * 문자의 앞부분에 있는 공백을 제거
 *
 * *앞쪽의 공백만 제거함에 유의
 *
 * @param str - 원본 문자열
 * @returns 앞부분 공백이 제거된 문자열
 */
function removeLeadingBlank(str: string) {
  if (!str || typeof str !== "string") return str;

  return str.replace(/^\s+/g, "");
}

/**
 * 검색어를 기준으로 라벨값을 포함하는 정규식 생성
 *
 * @param searchTerm - 검색어
 * @returns 정규식
 */
function getSearchTermRegex(searchTerm: string): RegExp {
  const searchTermWithoutSpaces = searchTerm.replace(/\s+/g, "");
  return new RegExp(searchTermWithoutSpaces, "gi");
}

/**
 * 문자열을 특정 구분자로 나눈 후 마지막 문자열 반환
 *
 * @param str - 원본 문자열
 * @param separator - 구분자
 * @param limit - 나눌 횟수 (선택 사항)
 * @returns 마지막 문자열
 */
function getLastStringAfterSplit({
  str,
  separator,
  limit,
}: {
  str: string;
  separator: string | RegExp;
  limit?: number;
}) {
  const splitStr = str.split(separator, limit);

  return splitStr[splitStr.length - 1];
}

/**
 * 값이 특정 색으로 조건부로 강조 되야하는 경우 사용
 * highlightColor의 default는 빨강
 *
 * @param label - 원본 문자열 또는 숫자
 * @param isHighlighted - 강조 여부
 * @param highlightColor - 강조 색상 (선택 사항)
 * @returns 강조된 문자열 또는 숫자
 */
function getHighlightedLabel({
  label,
  isHighlighted,
  highlightColor,
}: {
  label: string | number;
  isHighlighted: boolean;
  highlightColor?: string;
}) {
  if (!isHighlighted) return label;

  return (
    <span style={{ color: highlightColor || COLOR.pointWarning }}>{label}</span>
  );
}

/**
 * 하이픈이 추가된 송장번호를 반환
 * 택배사마다 하이픈 형식이 다름
 *
 * @param invoiceNo - 송장번호
 * @param parcelCompany - 택배사
 * @returns 하이픈이 추가된 송장번호
 */
export function getInvoiceNoWithHyphen({
  invoiceNo,
  parcelCompany,
}: {
  invoiceNo: string;
  parcelCompany: FulfillmentParcelCompany | undefined;
}) {
  if (!invoiceNo || !parcelCompany) return "";

  switch (parcelCompany) {
    case "daesin": {
      return invoiceNo.replace(/^([0-9]{4})([0-9]{3})([0-9]{6})$/, "$1-$2-$3");
    }

    default: {
      return invoiceNo.replace(
        /(^02.{0}|^01.{1}|[0-9]{4})([0-9]+)([0-9]{4})/,
        "$1-$2-$3"
      );
    }
  }
}

/**
 * 하이픈이 추가된 휴대폰 번호를 반환
 *
 * @param phoneNumber - 휴대폰 번호
 * @returns 하이픈이 추가된 휴대폰 번호
 */
function getPhoneNumberWithHyphen(phoneNumber: string) {
  if (!phoneNumber) return "";

  return phoneNumber
    .replace(/[^0-9]/, "")
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}

/**
 * 하이픈이 추가된 사업자등록번호를 번호를 반환
 *
 * @param brnNumber - 사업자등록번호
 * @returns 하이픈이 추가된 사업자등록번호
 */
function getBrnNumberWithHyphen(brnNumber: string) {
  if (!brnNumber) return "";

  return brnNumber
    .replace(/[^0-9]/, "")
    .replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`);
}

/**
 * 익명화 된 문자열로 변환
 *
 * @param source - 원본 문자열 또는 숫자
 * @param anonymizationLength - 익명화 하고싶은 문자 길이 (맨 뒷글자에서부터 익명화 됨)
 * @param anonymizationChar - 익명화에 사용할 문자 (기본값: "*")
 * @returns 익명화 된 문자열
 */
function getAnonymizationString({
  source,
  anonymizationLength,
  anonymizationChar = "*",
}: {
  source: string | number;
  anonymizationLength: number | "all";
  anonymizationChar?: string;
}) {
  if (!source) return "";

  const sourceStr = source.toString();

  let targetLength = 0;
  const sourceLength = sourceStr.length;
  if (anonymizationLength === "all") {
    targetLength = sourceLength;
  } else {
    targetLength =
      anonymizationLength <= sourceLength ? anonymizationLength : sourceLength;
  }

  const rex = new RegExp(".{0," + targetLength + "}$");

  const replacingChar = new Array(targetLength).fill(0).reduce((a, _) => {
    return (a = a + anonymizationChar);
  }, "");

  return source.toString().replace(rex, replacingChar);
}

/**
 * 문자열 또는 숫자에서 쉼표를 제거
 *
 * @param value - 원본 문자열 또는 숫자
 * @returns 쉼표가 제거된 문자열
 */
function removeComma(value: string | number) {
  return value.toString().replace(/,/gi, "");
}

/**
 * 쉼표를 제거한 후 숫자로 변환
 *
 * @param string - 원본 문자열
 * @returns 숫자로 변환된 값
 */
function removeCommaAndToNumber(string: string) {
  return parseInt(removeComma(string), 10);
}

/**
 * 문자열 또는 숫자에서 숫자와 소수점을 제외한 모든 문자 제거
 *
 * @param value - 원본 문자열 또는 숫자
 * @returns 숫자와 소수점만 남은 문자열
 */
function removeExceptNumbersAndDecimals(value: string | number) {
  return value.toString().replace(/[^-.0-9]/g, "");
}

/**
 * 문자열에서 공백을 제거한 후 소문자로 변환
 *
 * @param str - 원본 문자열
 * @returns 공백이 제거되고 소문자로 변환된 문자열
 */
function removeSpacesAndConvertToLowerCase(str: string) {
  return str.replace(/\s+/g, "").toLowerCase();
}

/**
 * 빈 데이터가 주어졌을 경우, 대시(-)를 반환
 *
 * @param textToReplace - 치환할 원본 데이터
 * @param includesZero - '빈 데이터'에 0이 포함되어야 하는 경우 (ex. 0도 '-'로 바뀜)
 * @returns 치환된 데이터
 */
function replaceEmptyToDash<Input>(
  textToReplace: Input | null | undefined,
  includesZero?: boolean
) {
  if (includesZero && (textToReplace === 0 || textToReplace === "0")) {
    return "-";
  }

  // 빈 문자열 별도 처리
  if (typeof textToReplace === "string" && !textToReplace.length) return "-";

  return textToReplace ?? "-";
}

/**
 * 문자열의 첫 글자를 대문자로 변환
 *
 * @param str - 원본 문자열
 * @returns 첫 글자가 대문자로 변환된 문자열
 */
const capitalizeFirstLetter = (str: string | undefined) => {
  if (!str) {
    return "";
  }

  const [first = "", ...rest] = str;

  return [first.toUpperCase(), ...rest].join("");
};

/**
 * 카멜 케이스 문자열을 케밥 케이스로 변환
 *
 * @param camelCaseString - 카멜 케이스 문자열
 * @returns 케밥 케이스로 변환된 문자열
 */
const convertCamelCaseToKebabCase = (camelCaseString: string) =>
  camelCaseString.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();

export {
  omitWithEllipsis,
  getTrimmedValue,
  getLastStringAfterSplit,
  getHighlightedLabel,
  getSearchTermRegex,
  removeSpacesAndConvertToLowerCase,
  getPhoneNumberWithHyphen,
  getAnonymizationString,
  removeComma,
  removeCommaAndToNumber,
  removeLeadingBlank,
  removeExceptNumbersAndDecimals,
  getBrnNumberWithHyphen,
  replaceEmptyToDash,
  capitalizeFirstLetter,
  convertCamelCaseToKebabCase,
};
