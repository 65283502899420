import { rest } from "msw";

import { getShipdaAdminDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import { GET_ADMIN_ORDER_LIST } from "./data";

export const getOrderList = rest.get(
  getShipdaAdminDevAPI("/admin/order"),
  (req, res, ctx) => {
    // TODO: api의 제작에 따라 param을 추가해서 로컬에서 테스트 해보기

    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_ADMIN_ORDER_LIST)
    );
  }
);
