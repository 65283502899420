export const GET_ADMIN_DEPOSIT_V2_LIST = {
  list: [
    {
      id: 123123,
      transactionDateTime: "2023-12-23",
      bankName: "신한은행",
      giroBankName: "카카오뱅크",
      depositName: "조형근",
      currency: "KRW",
      depositAmount: 20000,
      invoices: [
        {
          invoiceId: 123,
          shipmentId: 154233,
          serviceType: "general",
          isImport: true,
          matchedAmount: 20000,
          invoiceResult: "complete",
          remainingAmount: 0,
          overAmount: 0,
        },
      ],
      companyId: 111,
      depositResult: "complete",
      comment: null,
    },
    {
      id: 123123,
      transactionDateTime: "2023-12-23",
      bankName: "우리은행",
      giroBankName: "국민은행",
      depositName: "신지원",
      currency: "KRW",
      depositAmount: 20000,
      invoices: [
        {
          invoiceId: 1235,
          shipmentId: 154234,
          serviceType: "general",
          isImport: true,
          matchedAmount: 10000,
          invoiceResult: "complete",
          remainingAmount: 0,
          overAmount: 0,
        },
        {
          invoiceId: 1231,
          shipmentId: 154235,
          serviceType: "general",
          isImport: true,
          matchedAmount: 10000,
          invoiceResult: "unpaid",
          remainingAmount: 10000,
          overAmount: 0,
        },
      ],
      companyId: 114,
      depositResult: "complete",
      comment: null,
    },
  ],
  total: 2,
};
