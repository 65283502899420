import { rest } from "msw";

import { getShipdaAdminDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import {
  GET_ADMIN_IVE_TEAM_DATA,
  GET_ADMIN_NEWJEANS_TEAM_DATA,
  GET_ADMIN_TEAM_LIST,
} from "./data";

export const getTeamList = rest.get(
  getShipdaAdminDevAPI("/team/list"),
  (req, res, ctx) => {
    const searchType = req.url.searchParams.get("searchType");
    const searchTerm = req.url.searchParams.get("searchTerm");
    const perPage = req.url.searchParams.get("perPage");
    const page = req.url.searchParams.get("page");
    const startDate = req.url.searchParams.get("startDate");
    const endDate = req.url.searchParams.get("endDate");
    if (
      searchTerm === "뉴진스" &&
      searchType === "company" &&
      perPage === "20" &&
      page === "0"
    ) {
      return res(
        ctx.status(200),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(GET_ADMIN_NEWJEANS_TEAM_DATA)
      );
    }

    if (
      perPage === "20" &&
      page === "0" &&
      startDate === "2023-03-07T15:00:00.000Z" &&
      endDate === "2023-03-10T14:59:59.999Z"
    ) {
      return res(
        ctx.status(200),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(GET_ADMIN_IVE_TEAM_DATA)
      );
    }

    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_ADMIN_TEAM_LIST)
    );
  }
);
