import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { mobile } from "../../styles/functions";

import { ViewType } from ".";
import { MSWToolMode } from "./useChangeAllHandlers";

const MSWTool = styled.div<{
  isToolOpened: boolean;
  type: ViewType;
}>`
  position: fixed;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR.grayScale_800};
  border-radius: 8px;
  max-width: 1000px;

  ${({ isToolOpened, type }) => {
    switch (type) {
      case "web":
        if (isToolOpened) {
          return css`
            bottom: 60px;
            left: 8px;
            right: 8px;
            padding: 16px 8px 0;
          `;
        } else {
          return css`
            bottom: 60px;
            left: 8px;
          `;
        }
      case "PDA":
        if (isToolOpened) {
          return css`
            top: 8px;
            right: 8px;
            left: 90px;
            flex-direction: column-reverse;
            padding: 0 8px 8px;
          `;
        } else {
          return css`
            top: 8px;
            left: 90px;
            flex-direction: column-reverse;
          `;
        }
    }
  }}

  .toggle > svg {
    color: ${COLOR.wh};
    width: 2rem;
    height: 2rem;
  }

  .toggle > svg,
  .change-all-handlers svg {
    cursor: pointer;
  }

  .mode svg {
    width: 3rem;
    height: 3rem;
  }
`;

const HandlerList = styled.div`
  background-color: ${COLOR.wh};
  border-radius: 8px;
  padding: 0 8px 8px 8px;
  overflow-y: auto;
  height: 800px;

  ${mobile(css`
    height: calc(100vh - 100px);
  `)};

  > .table > table {
    svg {
      cursor: pointer;
    }
  }
`;

const HandlerHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  position: sticky;
  top: 0;
  background-color: ${COLOR.wh};

  ${mobile(css`
    grid-template-columns: 1fr;

    .input-section > div {
      min-width: initial !important;
    }
  `)}

  .tab {
    min-width: 222px;

    > div {
      flex-wrap: nowrap !important;

      button {
        margin-right: 8px;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

const ToggleButton = styled.div<{
  mode: MSWToolMode;
  isToolOpened: boolean;
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .toggle {
    ${({ isToolOpened }) =>
      !isToolOpened &&
      css`
        padding: 8px;
        border-radius: 8px;
      `}

    ${({ mode, isToolOpened, isActive }) =>
      !isToolOpened &&
      (mode === "error"
        ? css`
            background-color: ${COLOR.error_300};
          `
        : isActive &&
          css`
            background-color: ${COLOR.primary_600};
          `)}

  .change-all-handlers {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .handler-list {
    list-style: disc;
    list-style-position: inside;
    padding-left: 1rem;
  }
`;

export default {
  MSWTool,
  HandlerList,
  HandlerHeader,
  ToggleButton,
};
