import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../services/query";

import {
  QueryResponseHandlerCustomMessage,
  QueryResponseHandlerSuccessModalInfo,
} from "../../components/QueryResponseHandler";

import {
  DELETE_PROMOTION_BID_ID_REQ,
  DELETE_PROMOTION_BID_ID_RES,
  EDIT_PROMOTION_BID_ID_REQ,
  EDIT_PROMOTION_BID_ID_REQ_PATH_PARAMS,
  EDIT_PROMOTION_BID_ID_RES,
  EDIT_PROMOTION_REQ,
  EDIT_PROMOTION_REQ_PATH_PARAMS,
  EDIT_PROMOTION_RES,
  GET_ADMIN_PROMOTION_DETAIL_RES,
  GET_ADMIN_PROMOTION_INFO_REQ,
  GET_ADMIN_PROMOTION_INFO_RES,
  GET_ADMIN_PROMOTION_LIST_REQ,
  GET_ADMIN_PROMOTION_LIST_RES,
  UPDATE_PROMOTION_MANGER_REQ,
  UPDATE_PROMOTION_MANGER_RES,
} from "../../api-interfaces/shipda-api/adminPromotion";

export const ADMIN_PROMOTION_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_PROMOTION_QUERY" }] as const,

  getPromotionList: (params: GET_ADMIN_PROMOTION_LIST_REQ) =>
    [
      {
        ...ADMIN_PROMOTION_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "promotionList",
      },
    ] as const,

  getPromotionBasicInfo: (params: GET_ADMIN_PROMOTION_INFO_REQ) =>
    [
      {
        ...ADMIN_PROMOTION_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "promotionBasicInfo",
      },
    ] as const,

  getPromotionDetail: (listId: number | string) =>
    [
      {
        ...ADMIN_PROMOTION_QUERY_KEY_GEN.all()[0],
        listId,
        entity: "promotionDetail",
      },
    ] as const,
};

function useGetPromotionList(
  params: GET_ADMIN_PROMOTION_LIST_REQ,
  enabled: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_PROMOTION_QUERY_KEY_GEN.getPromotionList>,
    GET_ADMIN_PROMOTION_LIST_RES
  >({
    queryKey: ADMIN_PROMOTION_QUERY_KEY_GEN.getPromotionList(params),
    requestOptions: {
      method: "get",
      path: `/promotion`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    keepPreviousData: true,
    enabled,
  });

  return { ...queryResult };
}

function useGetPromotionBasicInfo(params: GET_ADMIN_PROMOTION_INFO_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_PROMOTION_QUERY_KEY_GEN.getPromotionBasicInfo>,
    GET_ADMIN_PROMOTION_INFO_RES
  >({
    queryKey: ADMIN_PROMOTION_QUERY_KEY_GEN.getPromotionBasicInfo(params),
    requestOptions: {
      method: "get",
      path: `/promotion/summary/info`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useGetPromotionDetail(listId: number | string) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_PROMOTION_QUERY_KEY_GEN.getPromotionDetail>,
    GET_ADMIN_PROMOTION_DETAIL_RES
  >({
    queryKey: ADMIN_PROMOTION_QUERY_KEY_GEN.getPromotionDetail(listId),
    requestOptions: {
      method: "get",
      path: `/promotion/${listId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useEditPromotion(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
  } | void
) {
  const { successModalInfo } = props || {};

  const mutation = useAppMutation<
    EDIT_PROMOTION_REQ,
    EDIT_PROMOTION_RES,
    EDIT_PROMOTION_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ promotionDetailId }) => `/promotion/${promotionDetailId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useEditPromotionBidId(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
  } | void
) {
  const { successModalInfo } = props || {};

  const mutation = useAppMutation<
    EDIT_PROMOTION_BID_ID_REQ,
    EDIT_PROMOTION_BID_ID_RES,
    EDIT_PROMOTION_BID_ID_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ promotionDetailId }) => `/promotion/bid/${promotionDetailId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({
          error,
          errorCode,
        }: {
          error: string | undefined;
          errorCode: string | undefined;
        }) =>
          ({
            "bid not found": {
              messageType: "titleOnly" as const,
              title: "의뢰를 찾을 수 없습니다.",
            },
            E323: {
              messageType: "titleOnly" as const,
              title: "다른 유저의 의뢰번호 입니다.",
            },

            E326: {
              messageType: "titleOnly" as const,
              title: "이미 등록된 의뢰번호입니다.",
            },
          }[errorCode ?? error ?? ""] ?? {
            title: "에러가 발생했습니다 개발자에게 문의해주세요.",
          });

        return getErrorMessage({
          error: failureInfo?.error,
          errorCode: failureInfo?.errorCode,
        });
      },
    },
  });

  return { ...mutation };
}

function useUpdatePromotionManager(props: {
  successModalInfo: QueryResponseHandlerSuccessModalInfo;
}) {
  const { successModalInfo } = props;

  const mutation = useAppMutation<
    UPDATE_PROMOTION_MANGER_REQ,
    UPDATE_PROMOTION_MANGER_RES
  >({
    requestOptions: {
      method: "patch",
      // TODO: pathParams를 사용하는 방식으로 수정
      /**
       * _customPath를 통해서만 request 가능 (/promotion/manager/{promotionDetailId})
       */
      path: "_customPath",
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useDeletePromotionBidId(props: {
  successModalInfo: QueryResponseHandlerSuccessModalInfo;
}) {
  const { successModalInfo } = props;

  const mutation = useAppMutation<
    DELETE_PROMOTION_BID_ID_REQ,
    DELETE_PROMOTION_BID_ID_RES
  >({
    requestOptions: {
      method: "delete",
      // TODO: pathParams를 사용하는 방식으로 수정
      /**
       * _customPath를 통해서만 request 가능 (/promotion/bid/{promotionDetailId})
       */
      path: "_customPath",
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}
const ADMIN_PROMOTION_QUERY = {
  useGetPromotionList,
  useGetPromotionBasicInfo,
  useGetPromotionDetail,
  useEditPromotion,
  useEditPromotionBidId,
  useUpdatePromotionManager,
  useDeletePromotionBidId,
};

export default ADMIN_PROMOTION_QUERY;
