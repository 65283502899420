import { css } from "styled-components";

import { ShipdaCurrentLanguage } from "../i18n/i18nForShipda";

export type TypeLevel =
  | "Head1"
  | "Head2"
  | "Head3"
  | "Head4"
  | "Head5"
  | "Head6"
  | "SubHead1"
  | "SubHead2"
  | "SubHead3"
  | "SubHead4"
  | "Body1"
  | "Body2"
  | "Body3"
  | "Body4"
  | "Body5"
  | "Body6"
  | "Body7"
  | "Body8"
  | "Caption";

export type FontFace = "Default_KR" | "Point" | "Point_ENG";

export type FontWeight = "ExtraBold" | "Bold" | "Regular";

/**
 *
 * @param type 타입 종류, Heading Level
 * @param weight 폰트 두께 (기본값 : "Regular")
 * @param face 폰트 종류 (기본값 : "Default_KR")
 */
export function setFontStyle(
  type: TypeLevel,
  weight?: FontWeight,
  face?: FontFace
) {
  if (!weight && !face)
    return css`
      ${setTypeLevel(type)}
      ${setFontWeight("Regular")}
      ${setFontFace("Default_KR")}
    `;

  if (!face)
    return css`
      ${setTypeLevel(type)}
      ${setFontWeight(weight)}
      ${setFontFace("Default_KR")}
    `;

  return css`
    ${setTypeLevel(type, face)}
    ${setFontWeight(weight)}
    ${setFontFace(face)}
  `;
}

function setTypeLevel(type: TypeLevel, face?: FontFace) {
  switch (type) {
    case "Head1": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 40px;
          line-height: 48px;
          letter-spacing: 0.4px;
        `;
      else
        return css`
          font-size: 40px;
          line-height: 48px;
          letter-spacing: -0.6px;
        `;
    }
    case "Head2": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 36px;
          line-height: 46px;
          letter-spacing: 0.4px;
        `;
      else
        return css`
          font-size: 36px;
          line-height: 46px;
          letter-spacing: -0.6px;
        `;
    }
    case "Head3": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 32px;
          line-height: 42px;
          letter-spacing: 0.4px;
        `;
      else
        return css`
          font-size: 32px;
          line-height: 42px;
          letter-spacing: -0.6px;
        `;
    }
    case "Head4": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 24px;
          line-height: 34px;
          letter-spacing: 0.4px;
        `;
      else
        return css`
          font-size: 24px;
          line-height: 34px;
          letter-spacing: -0.6px;
        `;
    }
    case "Head5": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.4px;
        `;
      else
        return css`
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.6px;
        `;
    }
    case "Head6": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.4px;
        `;
      else
        return css`
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.6px;
        `;
    }

    case "SubHead1": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.1px;
        `;
      else
        return css`
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.6px;
        `;
    }
    case "SubHead2": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
        `;
      else
        return css`
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.6px;
        `;
    }
    case "SubHead3": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.1px;
        `;
      else
        return css`
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.6px;
        `;
    }
    case "SubHead4": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.1px;
        `;
      else
        return css`
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.6px;
        `;
    }

    case "Body1": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 36px;
          line-height: 46px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 36px;
          line-height: 46px;
          letter-spacing: -0.6px;
        `;
    }
    case "Body2": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 32px;
          line-height: 42px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 32px;
          line-height: 42px;
          letter-spacing: -0.6px;
        `;
    }
    case "Body3": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 24px;
          line-height: 30px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 24px;
          line-height: 30px;
          letter-spacing: -0.6px;
        `;
    }
    case "Body4": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.6px;
        `;
    }
    case "Body5": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.6px;
        `;
    }
    case "Body6": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.6px;
        `;
    }
    case "Body7": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.6px;
        `;
    }
    case "Body8": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.6px;
        `;
    }

    case "Caption": {
      if (face === "Point" && ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-size: 10px;
          line-height: 14px;
          letter-spacing: -0.1px;
        `;
      else
        return css`
          font-size: 10px;
          line-height: 14px;
          letter-spacing: -0.6px;
        `;
    }
  }
}

function setFontFace(type?: FontFace) {
  switch (type) {
    case "Default_KR": {
      return css`
        font-family: "Noto sans KR", sans-serif;
      `;
    }
    case "Point": {
      if (ShipdaCurrentLanguage.currentLanguage === "en")
        return css`
          font-family: "Mulish", sans-serif;
        `;
      else
        return css`
          font-family: "NanumSquare", sans-serif;
        `;
    }
    // "KR" 일 때에 Point Mulish Font를 사용해야 하는 경우
    case "Point_ENG": {
      return css`
        font-family: "Mulish", sans-serif;
        letter-spacing: 0.1px;
      `;
    }
    default: {
      return css`
        font-family: "Noto sans KR", sans-serif;
      `;
    }
  }
}

function setFontWeight(type?: FontWeight) {
  switch (type) {
    case "ExtraBold": {
      return css`
        font-weight: 800;
      `;
    }
    case "Bold": {
      return css`
        font-weight: 700;
      `;
    }
    case "Regular": {
      return css`
        font-weight: 400;
      `;
    }
    default: {
      return css`
        font-weight: 400;
      `;
    }
  }
}
