import { rest } from "msw";

import { getShipdaAdminDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import { GET_ADMIN_DEPOSIT_V2_LIST } from "./data";

export const getDepositManagementV2List = rest.get(
  getShipdaAdminDevAPI("/financial/deposit/v2/list"),
  (req, res, ctx) => {
    const perPage = req.url.searchParams.get("perPage");
    const page = req.url.searchParams.get("page");

    if (perPage === "10" && page === "0") {
      return res(
        ctx.status(200),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(GET_ADMIN_DEPOSIT_V2_LIST)
      );
    }

    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_ADMIN_DEPOSIT_V2_LIST)
    );
  }
);
