import { atom } from "recoil";

import { PartnerManager } from "../../../types/forwarding/partner";

const PARTNER_ID = atom<number>({
  // key에 file structure를 반영하여 unique가 보장되도록 한다
  key: "forwarding/partner/atoms/PARTNER_ID",
  default: 0,
});

/** 클라이언트 사이드 매니저 추가 핸들링을 위한 매니저 리스트 */
const PARTNER_DETAIL_TEMP_MANAGER_LIST = atom<PartnerManager[]>({
  key: `forwarding/partner/atoms/PARTNER_DETAIL_TEMP_MANAGER_LIST`,
  default: [],
});

export default {
  PARTNER_ID,
  PARTNER_DETAIL_TEMP_MANAGER_LIST,
};
