import * as bofulInventoryHandlers from "./inventory/handlers";
import * as bofulLocationHandlers from "./location/handlers";
import * as bofulOrderHandlers from "./order/handlers";
import * as bofulReceivingHandlers from "./receiving/handlers";
import * as bofulReturningHandlers from "./returning/handlers";
import * as bofulScanHandlers from "./scan/handlers";
import * as bofulShippingHandlers from "./shipping/handlers";

export default {
  ...bofulReceivingHandlers,
  ...bofulShippingHandlers,
  ...bofulScanHandlers,
  ...bofulOrderHandlers,
  ...bofulInventoryHandlers,
  ...bofulLocationHandlers,
  ...bofulReturningHandlers,
};
