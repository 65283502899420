import { rest } from "msw";

import { getBofulDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import {
  ADMIN_RETURNING_LIST,
  PDA_RETURNING_DETAIL,
  USER_RETURNING_LIST,
} from "./data";

const getAdminReturningList = rest.get(
  getBofulDevAPI("/returning/admin/list"),
  (req, res, ctx) => {
    const data = ADMIN_RETURNING_LIST;

    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json(data));
  }
);

const getPDAReturningDetail = rest.get(
  getBofulDevAPI("/returning/list/:returningId"),
  (req, res, ctx) => {
    const data = PDA_RETURNING_DETAIL;

    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json(data));
  }
);

const getUserReturningList = rest.get(
  getBofulDevAPI("/returning/user/list"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(USER_RETURNING_LIST)
    );
  }
);

const getReturningTrackingInfoOfParcel = rest.get(
  getBofulDevAPI("/returning/user/tracking/parcel"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        level: 2,
        company: "cj",
        invoiceNo: "invoiceNo",
        address: "집",
        trackingDetails: [
          {
            remark: "비고",
            time: new Date(),
            name: "배송기사",
            phone: "전화번호",
            level: 2,
          },
        ],
      })
    )
);

export {
  getAdminReturningList,
  getPDAReturningDetail,
  getUserReturningList,
  getReturningTrackingInfoOfParcel,
};
