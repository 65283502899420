import { AdminPromotionBasicInfo } from "../../../types/forwarding/adminPromotion";

export const GET_ADMIN_PROMOTION_BASIC_INFO: AdminPromotionBasicInfo = {
  waitingCnt: 20,
  inProgressCnt: 10,
  allCnt: 30,
  finishedCnt: 40,
  pendingCnt: 30,
  canceledCnt: 10,
};

export const GET_ADMIN_PROMOTION_TABLE_INPROGRESS_LIST = {
  list: [
    {
      id: "1",
      status: "inProgress",
      bidId: 123123,
      createdAt: "2022-04-12T04:14:05.371Z",
      attachments: {},
      email: null,
      manager: null,
      phone: null,
      userName: null,
      user: {
        id: "3233",
        name: "조형근",
        phone: "01099544922",
        email: "geunee92@gmail.com",
        company: "셀러노트",
        createdAt: "2022-02-18T04:14:05.371Z",
        forwardingManager: {
          name: "방현정",
        },
      },
    },
    {
      id: "2",
      status: "inProgress",
      bidId: 123124,
      createdAt: "2022-04-18T04:14:05.371Z",
      attachments: {},
      email: null,
      manager: null,
      phone: null,
      userName: null,
      user: {
        id: "3246",
        name: "황선재",
        phone: "010000000",
        email: "geunee92@ship-da.com",
        company: "쉽다",
        createdAt: "2022-03-18T04:14:05.371Z",
        forwardingManager: {
          name: "황호진",
        },
      },
    },
    {
      id: "3",
      status: "inProgress",
      bidId: 123125,
      createdAt: "2022-04-20T04:14:05.371Z",
      attachments: {},
      email: null,
      manager: null,
      phone: null,
      userName: null,
      user: {
        id: "3235",
        name: "방현정",
        phone: "01012341231",
        email: "123@ac.kr",
        company: "어렵다",
        createdAt: "2022-01-18T04:14:05.371Z",
        forwardingManager: {
          name: "손석균",
        },
      },
    },
  ],
  total: 3,
};

export const GET_ADMIN_PROMOTION_TABLE_WAITING_LIST = {
  list: [
    {
      id: "1",
      status: "waiting",
      bidId: 123123,
      createdAt: "2022-04-12T04:14:05.371Z",
      attachments: {},
      email: null,
      manager: null,
      phone: null,
      userName: null,
      user: {
        id: "3233",
        name: "조형근",
        phone: "01099544922",
        email: "geunee92@gmail.com",
        company: "셀러노트",
        createdAt: "2022-02-18T04:14:05.371Z",
        forwardingManager: {
          name: "방현정",
        },
      },
    },
    {
      id: "2",
      status: "waiting",
      bidId: 123124,
      createdAt: "2022-04-18T04:14:05.371Z",
      attachments: {},
      email: null,
      manager: null,
      phone: null,
      userName: null,
      user: {
        id: "3246",
        name: "황선재",
        phone: "010000000",
        email: "geunee92@ship-da.com",
        company: "쉽다",
        createdAt: "2022-03-18T04:14:05.371Z",
        forwardingManager: {
          name: "황호진",
        },
      },
    },
    {
      id: "3",
      status: "waiting",
      bidId: 123125,
      createdAt: "2022-04-20T04:14:05.371Z",
      attachments: {},
      email: null,
      manager: null,
      phone: null,
      userName: null,
      user: {
        id: "3235",
        name: "방현정",
        phone: "01012341231",
        email: "123@ac.kr",
        company: "어렵다",
        createdAt: "2022-01-18T04:14:05.371Z",
        forwardingManager: {
          name: "손석균",
        },
      },
    },
  ],
  total: 3,
};

export const GET_ADMIN_PROMOTION_TABLE_PENDING_LIST = {
  list: [
    {
      id: "1",
      status: "pending",
      bidId: 123123,
      createdAt: "2022-04-12T04:14:05.371Z",
      attachments: {},
      email: null,
      manager: null,
      phone: null,
      userName: null,
      user: {
        id: "3233",
        name: "조형근",
        phone: "01099544922",
        email: "geunee92@gmail.com",
        company: "셀러노트",
        createdAt: "2022-02-18T04:14:05.371Z",
        forwardingManager: {
          name: "방현정",
        },
      },
    },
    {
      id: "2",
      status: "pending",
      bidId: 123124,
      createdAt: "2022-04-18T04:14:05.371Z",
      attachments: {},
      email: null,
      manager: null,
      phone: null,
      userName: null,
      user: {
        id: "3246",
        name: "황선재",
        phone: "010000000",
        email: "geunee92@ship-da.com",
        company: "쉽다",
        createdAt: "2022-03-18T04:14:05.371Z",
        forwardingManager: {
          name: "황호진",
        },
      },
    },
    {
      id: "3",
      status: "pending",
      bidId: 123125,
      createdAt: "2022-04-20T04:14:05.371Z",
      attachments: {},
      email: null,
      manager: null,
      phone: null,
      userName: null,
      user: {
        id: "3235",
        name: "방현정",
        phone: "01012341231",
        email: "123@ac.kr",
        company: "어렵다",
        createdAt: "2022-01-18T04:14:05.371Z",
        forwardingManager: {
          name: "손석균",
        },
      },
    },
  ],
  total: 3,
};

export const GET_ADMIN_PROMOTION_TABLE_FINISHED_LIST = {
  list: [
    {
      id: "1",
      status: "finished",
      bidId: 123233,
      createdAt: "2022-04-12T04:14:05.371Z",
      attachments: {},
      email: null,
      manager: null,
      phone: null,
      userName: null,
      user: {
        id: "3233",
        name: "조형근",
        phone: "01099544922",
        email: "geunee92@gmail.com",
        company: "셀러노트",
        createdAt: "2022-02-18T04:14:05.371Z",
        forwardingManager: {
          name: "방현정",
        },
      },
    },
    {
      id: "2",
      status: "finished",
      bidId: 123114,
      createdAt: "2022-04-18T04:14:05.371Z",
      attachments: {},
      email: null,
      manager: null,
      phone: null,
      userName: null,
      user: {
        id: "3246",
        name: "황선재",
        phone: "010000000",
        email: "geunee92@ship-da.com",
        company: "쉽다",
        createdAt: "2022-03-18T04:14:05.371Z",
        forwardingManager: {
          name: "황호진",
        },
      },
    },
    {
      id: "3",
      status: "finished",
      bidId: 123135,
      createdAt: "2022-04-20T04:14:05.371Z",
      attachments: {},
      email: null,
      manager: null,
      phone: null,
      userName: null,
      user: {
        id: "3235",
        name: "방현정",
        phone: "01012341231",
        email: "123@ac.kr",
        company: "어렵다",
        createdAt: "2022-01-18T04:14:05.371Z",
        forwardingManager: {
          name: "손석균",
        },
      },
    },
  ],
  total: 3,
};

export const GET_ADMIN_PROMOTION_DETAIL = {
  id: "1",
  status: "inProgress",
  bidId: 123123,
  createdAt: "2022-04-12T04:14:05.371Z",
  detailStatus: "unKnown",
  deactivateReason: "미정",
  activateFlag: true,
  email: null,
  manager: null,
  phone: null,
  companyName: null,
  note: "미정",
  promotion: {
    name: "FCL-container",
  },
  attachments: {
    bucket: "sellernote-files-dev",
    id: 2048,
    isAdmin: true,
    key: "CI_PL/cd6924d4-35a4-415b-91b7-a84987bf0599.pdf",
    name: "쉽다_견적서 (30).pdf",
  },
  user: {
    id: "3233",
    name: "조형근",
    phone: "01099544922",
    email: "geunee92@gmail.com",
    company: "셀러노트",
    createdAt: "2022-02-18T04:14:05.371Z",
    freightType: "FCL",
  },
};
