import {
  BidProjectStatus,
  BidServiceType,
  BidStatus,
} from "../../types/forwarding/bid";
import {
  BusinessManagementExcelListItem,
  BusinessManagementListItem,
} from "../../types/forwarding/businessManagement";

/**
 * 주어진 이유 코드를 한국어 이름으로 변환합니다.
 *
 * @param reason - 이유 코드 문자열
 * @returns 한국어 이름 문자열
 */
export const changeProceedReasonToNameKr = (reason: string) => {
  switch (reason) {
    case "underReview":
      return "견적 검토 중";
    case "changeBidProceed":
      return "새로운 견적 의뢰";
    case "scheduleProceed":
      return "진행 예정";
    case "inPrepared":
      return "진행 계획이나 화물 준비 중";
    case "unconfirmed":
      return "수입 계획 미확정";
    case "reviewLater":
      return "추후 진행 시 재검토";
    case "changeConditions":
      return "수입조건 변경";
    case "anotherBid":
      return "다른 의뢰 번호로 수주 완료";
    case "price":
      return "가격 경쟁력 부족";
    case "competitors":
      return "기존 포워더 사용";
    case "lostContact":
      return "연락 두절";
    case "cancelPlan":
      return "수입계획 취소";
    case "missed":
      return "부재중";
    case "noProgress":
      return "진행 물량 없음";
    case "fareCheck":
      return "단순 운임 체크";
    default:
      return "-";
  }
};

/**
 * 주어진 비즈니스 관리 항목의 상태를 한국어 이름으로 변환합니다.
 *
 * @param record - 비즈니스 관리 항목 객체
 * @returns 한국어 이름 문자열
 */
export const changeBusinessManagementStatusToNameKr = (
  record: BusinessManagementListItem | BusinessManagementExcelListItem
) => {
  if (record.status === "committed") {
    return "의뢰 받음";
  }
  if (record.status === "waiting") {
    return "사용자 선택 대기 중";
  }
  if (record.status === "waitingForExporterInfo") {
    return "수출자 정보 입력 대기 중";
  }
  if (record.status === "finished") {
    if (record.projectStatus === "failed") {
      return "실패";
    }
    return "취소";
  }
  if (record.status === "estimating") {
    return "견적 산출 중";
  }
  return "-";
};

/**
 * 주어진 비즈니스 관리 항목의 서비스 유형을 한국어 이름으로 변환합니다.
 *
 * @param record - 비즈니스 관리 항목 객체
 * @returns 한국어 이름 문자열
 */
export const changeBusinessManagementServiceTypeToNameKr = (
  record: BusinessManagementListItem
) => {
  if (record.serviceType === "oceanTicket") {
    return "오션티켓";
  }
  if (record.serviceType === "general") {
    return "일반의뢰";
  }
  if (record.serviceType === "consolidation") {
    return "창고입고 후 수입운송";
  }
  return "-";
};

/**
 * 주어진 서비스 유형과 특송 여부에 따라 한국어 이름으로 변환합니다.
 *
 * @param serviceType - 서비스 유형 문자열
 * @param isExpress - 특송 여부
 * @returns 한국어 이름 문자열
 */
export const changeBusinessManagementExcelDataServiceTypeToNameKr = (
  serviceType: string,
  isExpress: boolean
) => {
  if (isExpress) {
    return "특송";
  }
  if (serviceType === "oceanTicket") {
    return "오션티켓";
  }
  if (serviceType === "general") {
    return "일반의뢰";
  }
  if (serviceType === "consolidation") {
    return "묶음 배송";
  }
  return "-";
};

/**
 * 주어진 상태와 프로젝트 상태에 따라 한국어 이름으로 변환합니다.
 *
 * @param status - 견적 상태
 * @param projectStatus - (선택 사항) 프로젝트 상태
 * @returns 한국어 이름 문자열
 */
export const changeBusinessManagementExcelStatusToNameKr = (
  status: BidStatus,
  projectStatus?: BidProjectStatus
) => {
  if (status === "committed") {
    return "의뢰 받음";
  }
  if (status === "waiting") {
    return "사용자 선택 대기 중";
  }
  if (status === "waitingForExporterInfo") {
    return "수출자 정보 입력 대기 중";
  }
  if (status === "finished") {
    if (projectStatus === "failed") {
      return "실패";
    }
    return "취소";
  }
  if (status === "estimating") {
    return "견적 산출 중";
  }
  return "-";
};

/**
 * 주어진 서비스 유형을 한국어 이름으로 변환합니다.
 *
 * @param serviceType - 서비스 유형
 * @returns 한국어 이름 문자열
 */
export const changeServiceTypeToNameKr = (serviceType: BidServiceType) => {
  if (serviceType === "oceanTicket") {
    return "오션티켓";
  }
  if (serviceType === "general") {
    return "일반의뢰";
  }
  if (serviceType === "consolidation") {
    return "창고입고 후 수입운송";
  }
  return "-";
};
