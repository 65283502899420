import { atom } from "recoil";

const ADMIN_INVOICE_ID = atom<number | undefined>({
  key: "forwarding/invoice/atoms/ADMIN_INVOICE_ID",
  default: undefined,
});

const ADMIN_TRELLO_CARD_ID = atom<number | undefined>({
  key: "forwarding/invoice/atoms/ADMIN_TRELLO_CARD_ID",
  default: undefined,
});

export default {
  ADMIN_INVOICE_ID,
  ADMIN_TRELLO_CARD_ID,
};
