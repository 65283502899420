import { atom } from "recoil";

const DEPOSIT_REQUEST_AMOUNT = atom<number>({
  key: "forwarding/adminSettlement/atoms/ADMIN_SETTLEMENT_DEPOSIT_REQUEST_AMOUNT",
  default: 0,
});

const DEPOSIT_INVOICE_ID = atom<number[]>({
  key: "forwarding/adminSettlement/atoms/ADMIN_SETTLEMENT_DEPOSIT_INVOICE_ID",
  default: [],
});

const WITHDRAWAL_REQUEST_AMOUNT = atom<number>({
  key: "forwarding/adminSettlement/atoms/ADMIN_SETTLEMENT_WITHDRAWAL_REQUEST_AMOUNT",
  default: 0,
});

const WITHDRAWAL_INVOICE_ID = atom<number[]>({
  key: "forwarding/adminSettlement/atoms/ADMIN_SETTLEMENT_WITHDRAWAL_INVOICE_ID",
  default: [],
});

export default {
  DEPOSIT_REQUEST_AMOUNT,
  DEPOSIT_INVOICE_ID,
  WITHDRAWAL_REQUEST_AMOUNT,
  WITHDRAWAL_INVOICE_ID,
};
