export const DUPLICATE_SKU_LIST = [
  {
    id: 3,
    itemName: "후르타목줄",
    productCode: "A54544",
    managementCode: "A456456",
    barCode: "123123123",
    userId: 399,
    bidId: 1,
    category: "애견용품",
    buyerId: 1,
    buyingURL: null,
    materialPackageType: "basic",
    returningCondition: "openNotUsed",
    createdAt: "2021-05-13T06:48:50.389Z",
    updatedAt: "2022-06-08T07:09:48.000Z",
    deletedAt: null,
    packages: [],
  },
  {
    id: 4,
    itemName: "후르타목줄444444",
    productCode: "A54544",
    managementCode: "A456456",
    barCode: "123123123",
    userId: 399,
    bidId: 1,
    category: "애견용품",
    buyerId: 1,
    buyingURL: null,
    materialPackageType: "basic",
    returningCondition: "openNotUsed",
    createdAt: "2021-05-13T06:48:50.389Z",
    updatedAt: "2022-06-08T07:09:48.000Z",
    deletedAt: null,
    packages: [],
  },
  {
    id: 5,
    itemName: "후르타목줄666666",
    productCode: "A54544",
    managementCode: "A456456",
    barCode: "123123123",
    userId: 399,
    bidId: 1,
    category: "애견용품",
    buyerId: 1,
    buyingURL: null,
    materialPackageType: "basic",
    returningCondition: "openNotUsed",
    createdAt: "2021-05-13T06:48:50.389Z",
    updatedAt: "2022-06-08T07:09:48.000Z",
    deletedAt: null,
    packages: [],
  },
];
